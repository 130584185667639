<template>
  <div :class="backgroundClassName">

    <div :class="cardClassName" v-for="n in items" :key="n.key">
      <div class="text-center text-subtitle-1 font-weight-black layerName">{{ n.layerName }}</div>
      <v-card class="mt-1" height="50px" width="100%" style="overflow:hidden;">
        <div class="text-open-sans text-center mt-0 pt-0 pb-0 text-overline cardTitle">{{ n.name }}
        </div>
          <v-card-text class="text-open-sans text-center mt-0" :style="getValueTagStyleString(n.value)">
            {{ n.value }}
            {{ n.unit == "deg"? "º" : "" }}
            <sup v-if="n.unit && n.unit != 'deg'"> {{n.unit}}</sup>
          </v-card-text>

        <v-btn class="mx-2 removeButton" fab dark x-small color="#004a82" @click="deleteItem(n)">
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
      </v-card>
    </div>

    <div :class="buttonContainerClassName">
      <select-series-dialog :model="value_names" ref="selectDialog" :selectMany="true" @send-message="dialogChangedSeries" />
      <v-btn class="mx-2" fab dark color="#004a82" @click="showSelectDialog()">
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>

  </div>

</template>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.background_vertical {
  background-color: white;
  border-radius: 10px;
  flex: 1 1 100%;
  height: 50px;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 5px;
}

.background_horizontal {
  background-color: white;
  border-radius: 10px;
  flex: 1 1 100%;
  height: 50px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 5px;
  display: flex;
  min-height: 100px;
}

.removeButton {
  position: absolute;
  right: -6px;
  width: 15px;
  height: 15px;
  top: 3px;
}

.cardTitle{
  white-space: nowrap;
  margin-bottom: -10px;
  margin-right: 20px;
  margin-left: 20px;
}

.unitText {
  position: absolute;
  right: 0px;
  width: 30%;
  height: 20px;
  top: 15px;
  background-color: red;
}

.text-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.valueText{
  margin-left: 10px;
  background: blue;
}

.text-variable-size {}

.data-card-container_vertical {
  padding: 2px;
  background-color: transparent;
  margin-bottom: 2px;
}

.data-card-container_horizontal {
  padding: 2px;
  background-color: transparent;
  margin-bottom: 2px;
  max-width: 100px;
}

.row-container {
  display: inline-flex;
}

.slide-container {
  flex: 1 1 auto;
}

.button-container_vertical {
  padding-top: 10px;
  text-align: center;
}

.button-container_horizontal {
  padding-top: 20px;
  text-align: center;
}

.layerName {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<script>
import { EventBus, FSPModel } from '../Globals'
import { FSPData } from './FSPLayers/FSPData'
import SelectSeriesDialog from './SelectSeriesDialog.vue'

export default {
  name: 'DataView',
  components: { SelectSeriesDialog },
  props: ['vertical'],

  data: () => ({
    items: [],
    value_names: [],
    cardClassName: 'data-card-container_horizontal',
    backgroundClassName: 'background_horizontal',
    buttonContainerClassName: 'button-container_horizontal'
  }),
  methods: {
    showSelectDialog () {
      this.$refs.selectDialog.showDialog()
    },

    dialogChangedSeries (selectedSeries) {
      const processedSelectedSeries = []
      for (const s of selectedSeries) {
        // All Sources
        if (s.layerId === -1) {
          const datas = FSPModel.getLayersOfType(FSPData)
          for (const d of datas) {
            if (d.series.hasSeries(s.seriesName)) {
              processedSelectedSeries.push({ layerId: d.id, seriesName: s.seriesName })
            }
          }
        } else {
          // If a layer is selected then the previously shown series must go
          // for (const s of selectedSeries) {
          //   this.value_names = this.value_names.filter(v => v.layerId !== s.layerId)
          // }

          if (!processedSelectedSeries.includes(s)) {
            processedSelectedSeries.push(s)
          }
        }
      }

      for (const x of processedSelectedSeries) {
        if (!this.value_names.some(y => y.layerId === x.layerId && y.seriesName === x.seriesName)) {
          this.value_names.push(x)
        }
      }

      this.showTime(FSPModel.clock.playerTime)
    },

    showTime (t) {
      this.items = []
      for (const n of this.value_names) {
        const data = FSPModel.getFSPDataWithId(n.layerId)
        if (data != null) {
          const row = data.getRowForTime(t)
          const vs = data.getSeries(n.seriesName)
          this.items.push({
            layerName: data.name,
            layerId: n.layerId,
            name: n.seriesName,
            value: isNaN(vs[row]) ? '-' : Number(vs[row]).toFixed(2),
            unit: isNaN(vs[row]) ? '' : data.getUnits(n.seriesName),
            key: n.seriesName + '_' + n.layerId
          })
        }
      }

      // If vertical, we remove repeated titles
      if (this.vertical) {
        this.items = this.items.sort((i0, i1) => i0.layerId - i1.layerId)
        for (var i = this.items.length - 1; i > 0; i--) {
          if (this.items[i].layerName === this.items[i - 1].layerName) {
            this.items[i].layerName = ''
          }
        }
      }
    },

    getValueTagStyleString (str) {
      const nCar = str === '-' ? 4 : str.length
      const containerSize = 100
      var size = containerSize / nCar
      size = Math.min(size, 23)
      return `font-size: ${size}px; padding: 3px;`
    },

    deleteItem (item) {
      this.value_names = this.value_names.filter(v => {
        return item.layerId !== v.layerId || item.name !== v.seriesName
      })

      this.$forceUpdate()
      this.showTime(FSPModel.clock.playerTime)
    }
  },
  mounted () {
    EventBus.$on(EventBus.LAYER_LOADED, () => {
    })
    EventBus.$on(EventBus.TIME_CHANGED, (t) => {
      this.showTime(t)
    })
    EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
      this.value_names = this.value_names.filter(n => n[0] !== id)
      this.showTime(FSPModel.clock.playerTime)
    })
  },

  watch: {
    vertical () {
      if (this.vertical) {
        this.cardClassName = 'data-card-container_vertical'
        this.backgroundClassName = 'background_vertical'
        this.buttonContainerClassName = 'button-container_vertical'
      } else {
        this.cardClassName = 'data-card-container_horizontal'
        this.backgroundClassName = 'background_horizontal'
        this.buttonContainerClassName = 'button-container_horizontal'
      }
      this.showTime(FSPModel.clock.playerTime)
    }
  }

}
</script>
