import { FSPModel } from '../../Globals'
import { stringToDate } from '../TimeUtils'
import { TimeFrameFSPLayer } from './FSPLayer'

function getInfo (mediainfo, file, onDone) {
  if (file) {
    const getSize = () => file.size
    const readChunk = (chunkSize, offset) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
      })

    mediainfo
      .analyzeData(getSize, readChunk)
      .then((result) => {
        onDone(result)
      })
      .catch((error) => {
        console.error(`An error occured:\n${error.stack}`)
      })
  }
}

function getLocalVideoDuration (file, onDurationExtracted) {
  var video = document.createElement('video')
  video.preload = 'metadata'

  video.addEventListener('loadedmetadata', function (e) {
    window.URL.revokeObjectURL(video.src)
    var duration = video.duration
    video.remove()
    onDurationExtracted(duration)
  })

  video.addEventListener('loadeddata', function (e) {
    console.log('video loadeddata')
  })

  video.addEventListener('stalled', (event) => {
    console.log('video stalled')
  })

  video.src = URL.createObjectURL(file)
}

export class VideoLayer extends TimeFrameFSPLayer {
  constructor (name, file, creationDate, durationSeconds) {
    super(name)
    this.file = file
    this.url = (window.URL) ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
    this.creationDate = creationDate
    this.durationSeconds = durationSeconds
  }

  get minDate () {
    return new Date(this.creationDate)
  }

  get maxDate () {
    const minDate = this.minDate
    const secs = minDate.getSeconds() + this.durationSeconds
    return new Date(minDate.setSeconds(secs))
  }

  set minDate (date) {
    this.creationDate = date
    this.notifyChange()
  }
}

export function createVideoLayerPromise (name, file) {
  return new Promise((resolve) => {
    require('mediainfo.js')().then((mediainfo) => {
      getInfo(mediainfo, file, (result) => {
        // FAILED TEST TO CONVERT VIDEO: NEEDED ORIGIN ISOLATION videoToH264(file)

        const track = result.media.track[0]
        let creationDate = stringToDate(track.Encoded_Date)
        if (isNaN(creationDate)) {
          creationDate = stringToDate(track.Recorded_Date)
        }
        if (isNaN(creationDate)) {
          console.error('Video with no date')
          // Error handling
          creationDate = FSPModel.clock.playerTime ? FSPModel.clock.playerTime : new Date()
        }

        var duration = Number(track.Duration)
        if (isNaN(duration)) {
          getLocalVideoDuration(file, (duration) => {
            const layer = new VideoLayer(name, file, new Date(creationDate), duration)
            resolve(layer)
          })
        } else {
          const layer = new VideoLayer(name, file, new Date(creationDate), duration)
          resolve(layer)
        }
      })
    })
  })
}
