import { FSPModel } from '../../Globals'

var seriesColorPaletteIndex = 0; 
const seriesColorPalette = ['rgb(6,150,104)', 'rgb(142,212,198)', 'rgb(32,80,46)', 'rgb(64,225,140)', 'rgb(51,52,174)', 'rgb(223,204,250)', 'rgb(135,48,115)', 'rgb(68,124,254)', 'rgb(197,223,114)', 'rgb(55,68,117)', 'rgb(242,131,227)', 'rgb(90,136,174)', 'rgb(54,229,21)', 'rgb(231,23,97)', 'rgb(248,204,166)'];

var seriesColors = new Map();
function getPlotColor(layerId, name){
  let key = layerId + name;
  if (seriesColors[key] == undefined){
    seriesColors[key] = seriesColorPalette[seriesColorPaletteIndex++ % seriesColorPalette.length];
  }
  return seriesColors[key];
}

function gaussianSmooth (vector, variance) {
  const avg = (v) =>{
    return v.reduce((a,b) => a+b, 0)/v.length;
  }

  var t_avg = avg(vector)*variance;
  var ret = Array(vector.length);
  for (var i = 0; i < vector.length; i++) {
    (function () {
      var prev = i>0 ? ret[i-1] : vector[i];
      var next = i<vector.length ? vector[i] : vector[i-1];
      if (!isNaN(t_avg) && !isNaN(prev) && !isNaN(vector[i]) && !isNaN(next)){
        ret[i] = avg([t_avg, avg([prev, vector[i], next])]);
      }else{
        ret[i] = vector[i];
      }
      
    })();
  }
  return ret;
}

function smoothing (vector) {
  var ret = Array(vector.length);
  ret[0] = vector[0]
  for (var i = 1; i < vector.length-1; i++) {
    var newVal = 0.2 * vector[i-1] + 0.6 * vector[i] + 0.2 * vector[i+1];
    ret[i] = isNaN(newVal)? vector[i] : newVal;
  }
  ret[ret.length-1] = vector[vector.length-1]
  return ret;
}



export class PlotTimeSeries {
  constructor (layerId, seriesName) {
    const data = FSPModel.getFSPDataWithId(layerId)
    this.name = seriesName
    this.data = data.getTimeSeries(seriesName)
    this.layerId = data.id
    //this.color = data.getColorForSeries(seriesName)
    this.color = getPlotColor(layerId, seriesName);

    var ys = this.data.map(p => p.y);

    if (true){
      //ys = gaussianSmooth(ys, 0.5)
      ys = smoothing(ys)
      for(var i = 0; i < ys.length; i++){
        this.data[i].y = ys[i];
      }
    }

    ys = ys.filter(v => !isNaN(v))
    this.minY = Math.min(...ys)
    this.maxY = Math.max(...ys)
  }

  getDataWindowIndices (minTime, maxTime) {
    const chunkSize = 100
    const data = this.data
    if (data.length <= chunkSize) {
      return [0, data.length - 1]
    }

    for (var minIndex = 0; minIndex < data.length; minIndex += chunkSize) {
      const nextIndex = Math.min(data.length - 1, minIndex + chunkSize)
      if (data[nextIndex].x >= minTime) {
        break
      }
    }

    for (var maxIndex = data.length - 1; maxIndex >= 0; maxIndex -= chunkSize) {
      const nextIndex = Math.max(0, maxIndex - chunkSize)
      if (data[nextIndex].x <= maxTime) {
        break
      }
    }

    return [minIndex, maxIndex]
  }

  getDataSlice (minTime, maxTime) {
    var windowIndices = this.getDataWindowIndices(minTime, maxTime)
    if (
      this.cachedDataSlice == undefined ||
        this.cachedIndices == undefined ||
        this.cachedIndices != windowIndices
    ) {
      this.cachedDataSlice = this.data.slice(
        windowIndices[0],
        windowIndices[1]
      )
      this.cachedIndices = windowIndices;

      if (false){
        //Cleaning data (removing repeated)
        var prevValue = NaN
        var values = []
        for (var d of this.cachedDataSlice){
          if (d.y != prevValue && !isNaN(d.y)/* || d.y == 0.0*/){
            values.push(d);
          }
          prevValue = d.y;
        }
        this.cachedDataSlice = values;
      }
    }

    return this.cachedDataSlice
  }
}
