export const NO_TIME_STRING = '--:--'

export function formatTimeAsHH_MM_SS(t) {
    if (t == null || isNaN(t)) {
        return NO_TIME_STRING
    }
    var formatter = new Intl.DateTimeFormat('en', {
        hour: '2-digit', minute: '2-digit', second: '2-digit',
        hourCycle: 'h23'
    });
    return formatter.format(t);
}


export function stringToDate(str) {
    if (typeof str != "string"){
        return new Date(NaN);
    }
    var date = new Date(str);
    if (isNaN(date)) {
        var s = str;
        if (s.startsWith("UTC ")) {
            s = s.slice(4);
            s = s.replace(" ", "T");
        }
        date = new Date(s);
    }

    if (isNaN(date)) {
        var s = str;
        if (s.match("[0-9]+:[0-9]+:[0-9]+ [0-9]+:[0-9]+:[0-9]+")) {
            s = s.replace(":", "/").replace(":", "/")
            date = new Date(s);
        }
    }

    // if (isNaN(date)){
    //     error ="Unable to parse date " + str
    //     console.error(error)
    //     throw error
    // }

    return date;
}