<template>
    <div class="buttonAndVideoContainer">
        <select-layer-widget ref="select_layer_widget" @send-message="dialogChangedLayers" class="selectLayerWidget"
            :selectVideoLayers="true">
        </select-layer-widget>

        <v-icon v-if="videoLayer == null" x-large color="white" class="noVideo">mdi-video-off</v-icon>

        <div ref="videoContainer" class="background player">
            <video ref="player" class="player" muted="true" playsinline>
                Your browser does not support HTML video.
            </video>
        </div>

        <v-alert v-if="videoNotSupported" prominent type="error" style="position: relative;top: 30%;">
            This video format is not supported by this browser.
        </v-alert>

        <v-btn class="muteButton" @click="switchVolume()">
            <v-icon>{{ muted ? "mdi-volume-off" : "mdi-volume-high" }}</v-icon>
        </v-btn>
    </div>
</template>

<style scoped>
.noVideo {
    top: 30%;
    z-index: 99999;
    text-align: center;
    display: block;
    width: 100%;
}

.muteButton {
    position: absolute;
    bottom: 35px;
    right: 15px;
    z-index: 99999;
    width: 30px;
    height: 40px;
    border-radius: 10px;
}

.buttonAndVideoContainer {
    height: 100%;
}

@media not all and (min-resolution:.001dpcm) {
    .player {
        object-fit: fill;
        margin: 0 auto;
        width: 100%;
        height: auto;
    }
}

.player {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
}

/* .background[data-v-8e4a65e8] {
    background-color: black;
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    height: 100%;
} */

.background {
    background-color: black;
    display: flex;
    position: absolute;
    top: 0px;
    width: 100%;
    height: calc(100% - 25px);
}

.selectLayerWidget {
    position: relative;
    top: 10px;
    left: 10px;
    z-index: 99999
}
</style>

<script>

import { EventBus, FSPModel, PAUSED, PLAY } from '../../Globals'

import { VideoLayer } from '../FSPLayers/VideoLayer'
import SelectLayerWidget from '../SelectLayerWidget.vue'

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export default {
  name: 'VideoView',
  components: {
    SelectLayerWidget
  },
  data: () => ({
    videoLayer: null,
    muted: true,
    videoNotSupported: false
  }),
  methods: {
    mountVideoLayer (layer) {
      this.videoNotSupported = false
      this.videoLayer = layer
      this.$refs.player.src = layer.url
    },
    unmountVideoLayer () {
      if (this.$refs.select_layer_widget) {
        this.$refs.select_layer_widget.unselect(this.videoLayer.id)
      }
      this.videoLayer = null
      const video = this.$refs.player
      video.pause()
      video.removeAttribute('src') // empty source
      video.load()
    },
    dialogChangedLayers (layers) {
      if (layers.length > 0) {
        const layer = FSPModel.getLayerById(layers[0])
        console.log('Mounting video ' + layer.name)
        this.mountVideoLayer(layer)
        this.$refs.select_layer_widget.select(layer.id)
      }
    },

    onTimeChanged (t) {
      const video = this.$refs.player
      if (video && this.videoLayer) {
        const videoDuration = (this.videoLayer.maxDate - this.videoLayer.minDate) / 1000
        const videoTime = (t - this.videoLayer.minDate) / 1000

        const videoDelay = video.currentTime - videoTime
        if (Math.abs(videoDelay) > 2.0) {
          // Resetting time
          video.currentTime = clamp(videoTime, 0, videoDuration)
          if (!video.paused) {
            console.log(`Resetting video time. Delay ${videoDelay}`)
          }
        }

        if (FSPModel.clock.state === PLAY &&
                    videoTime >= 0 &&
                    videoTime <= videoDuration
        ) {
          console.log('Video play')
          video.play()
        } else {
          if (!video.paused) {
            console.log('Video pause')
            video.pause()
          }
        }
      }
    },

    switchVolume () {
      this.muted = !this.muted
      const video = this.$refs.player
      video.muted = this.muted
    }
  },
  mounted () {
    EventBus.$on(EventBus.TIME_CHANGED, (t) => {
      this.onTimeChanged(t)
    })

    EventBus.$on(EventBus.SPEEDUP_CHANGED, (f) => {
      // TODO FIX MATCHING
      const video = this.$refs.player
      if (video && this.videoLayer) {
        try {
          video.playbackRate = f
        } catch (e) {
          video.playbackRate = 1.0
        }
      }
    })

    EventBus.$on(EventBus.PLAYBACK_CHANGED, (state) => {
      if (state === PAUSED) {
        const video = this.$refs.player
        if (video) {
          video.pause()
        }
      }
    })

    EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
      if (this.videoLayer) {
        if (id === this.videoLayer.id) {
          this.unmountVideoLayer()
        }
      }
    })

    EventBus.$on(EventBus.LAYER_CHANGED, (id) => {
      if (this.videoLayer != null && this.videoLayer.id === id) {
        this.onTimeChanged(FSPModel.clock.playerTime)
      }
    })

    // Mount any video
    const vl = FSPModel.getLayersOfType(VideoLayer)
    if (vl.length > 0) {
      this.mountVideoLayer(vl[0])
    }

    const video = this.$refs.player
    video.addEventListener('error', (e) => {
      console.error('Error loading video source')
      this.videoNotSupported = true
    })
  },

  destroyed () {
    // TODO CHECK
    // EventBus.$off(EventBus.TIME_CHANGED)
    // EventBus.$off(EventBus.SPEEDUP_CHANGED)
    // EventBus.$off(EventBus.PLAYBACK_CHANGED)
    // EventBus.$off(EventBus.LAYER_REMOVED)
    // EventBus.$off(EventBus.LAYER_CHANGED)
  },

  watch: {
  }
}
</script>
