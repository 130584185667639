<template>
  <v-dialog v-model="dialog" width="720" style="z-index: 999999999" :hide-overlay="false" :dark="true">
    <v-card style="background-color: #154b65;">
      <div class="dialog_title">LOG LAYER SETTINGS</div>

      <v-container class="white--text">
        <v-row class="ma-3">
          <v-text-field ref="nf" clearable dark label="Layer Name" hide-details="auto" v-model="name"
            :error-messages="nameErrorMsg"
            style="width: 100%;" />
          <div style="height: 20px; width: 100%;"></div>
          <v-text-field ref="nf" readonly dark label="Start Time" hide-details="auto" v-model="datetime"
            style="width: 400px;" />
          <time-dialog ref="time_dialog"></time-dialog>
          <v-btn class="mx-2" small fab dark color="#004a82" @click="openTimeDialog()" style="margin-top: 10px;">
            <v-icon dark> mdi-clock-time-four </v-icon>
          </v-btn>
        </v-row>
        <v-row class="ma-3">
          <p style="width: 100%; font-size: 13px;">Boat Icon </p>
          <v-img max-height="80px" max-width="80px" :src="icon"></v-img>
          <div style="width:80px; height:80px; ">
            <drop-file-area class="ml-5" @onFiles="onNewIconURL($event)" @files="onNewIconURL" acceptedFiles=".png"></drop-file-area>
          </div>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="saveChangesAndLeave();" :disabled="nameErrorMsg != null"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.v-label {
  color: white !important;
}

.v-input input {
  max-height: 32px;
  color: white !important;
  opacity: 1;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot::before {
  border-color: rgba(255, 255, 255, 255) !important;
}
</style>

<script>
import { FSPModel } from '../Globals'
import DropFileArea from './DropFileArea.vue'
import { FSPData } from './FSPLayers/FSPData.js'
import TimeDialog from './TimeDialog.vue'

var openDialogLayerId = -1

export default {
  name: 'DataLayerInfoDialog',

  components: { DropFileArea, TimeDialog },

  data: () => ({
    layers: [],
    dialog: false,
    datetime: new Date(),
    name: 'ok',
    layerId: null,
    icon: null,
    nameErrorMsg: null,
    ruleUniqueName: value => {
      for (const s of FSPModel.layers) {
        if (s.name === value && s.id !== openDialogLayerId) {
          return 'This name belongs to another layer.'
        }
      }
      return true
    }
  }),

  mounted () { },

  methods: {
    openDialog (datasetId) {
      const fspData = FSPModel.getFSPDataWithId(datasetId)
      this.layerId = fspData.id
      openDialogLayerId = fspData.id
      if (fspData != null) {
        this.dialog = true
        this.name = fspData.name
        this.datetime = fspData.minDate
        this.icon = fspData.boatIcon
      }
    },

    saveChangesAndLeave () {
      const layer = FSPModel.getLayerById(this.layerId)
      if (layer instanceof FSPData) {
        layer.changeData(this.name, this.datetime, this.icon)
      }
      this.dialog = false
      // TODO OVERLAY PROBLEM PATCH
      const overlay = document.getElementsByClassName('v-overlay')
      overlay[0].remove()
    },

    createObjectURL (object) {
      return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object)
    },

    onNewIconURL (files) {
      this.icon = this.createObjectURL(files[0])
    },

    openTimeDialog () {
      this.$refs.time_dialog.openDialog(this.datetime)

      this.$refs.time_dialog.$on('onChange', (date) => {
        console.log(date)
        this.datetime = date
      })
    }
  },

  watch: {
    name (newValue, oldValue) {
      this.nameErrorMsg = null
      if (newValue == null || newValue.length === 0) {
        this.nameErrorMsg = 'Empty name.'
      } else {
        for (const s of FSPModel.layers) {
          if (s.name === newValue && s.id !== this.layerId) {
            this.nameErrorMsg = 'Repeated name.'
          }
        }
      }
    },

    dialog () {
      console.log(`Dialog open: ${this.dialog}`)
    }
  }
}
</script>
