import { Line, mixins } from 'vue-chartjs'
import { FSPModel } from '../../Globals'
const { reactiveProp } = mixins

const data = {
  datasets: []
}

const options = {
  legend: {
    position: 'top',
    align: 'start',
    labels: {
      padding: 10,
      fontSize: 14,
      filter: function (item, chart) {
        // Logic to remove a particular legend item goes here
        return item.text !== '_FLAGS'
      }
    }
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const index = tooltipItem.index
        const dataset = data.datasets[tooltipItem.datasetIndex]
        const y = dataset.data[index].y
        const res = dataset.label + ': ' + Number(y).toFixed(2)
        if (dataset.unit) {
          const unit = dataset.unit === 'deg' ? 'º' : ' ' + dataset.unit
          return res + unit
        } else {
          return res
        }
      }
    },
    backgroundColor: '#FFF',
    titleFontSize: 16,
    titleFontColor: '#0066ff',
    bodyFontColor: '#000',
    bodyFontSize: 24,
    displayColors: false
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        ticks: {
          source: 'labels',
          min: 0,
          max: 100,
          fontColor: 'rgba(50, 51, 102, 1)',
          fontSize: 18,
          maxRotation: 0,
          autoSkip: false,
          callback: null, // Set in Update()
          time: {
            stepSize: 1,
            unit: 'second',
            displayFormats: {
              quarter: 'HH MM'
            }
          }
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          autoSkip: true,
          autoSkipPadding: 3,
          min: 0,
          max: 200,
          fontSize: 16,
          callback: function (val, index) {
            const yAxis = this.chart.options.scales.yAxes[0]
            return yAxis.ticks.max > val &&
                   yAxis.ticks.min < val ? val.toFixed(2) : null
          }
        }
      }
    ]
  },
  animation: {
    duration: 0
  },
  responsive: true,
  maintainAspectRatio: false
}

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options', 'series', 'timeRange', 'adjustYAxis'],
  data () {
    return {
      myChartData: data,
      myChartOptions: options,
      flagImage: new Image()
    }
  },
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object

    this.addPlugin({

      beforeDraw: chart => {
        var ctx = chart.chart.ctx
        var xAxis = chart.scales['x-axis-0']
        var yAxis = chart.scales['y-axis-0']

        const time = FSPModel.clock.playerTime
        var cx = xAxis.getPixelForValue(time)

        // set line stroke and line width
        ctx.save()
        ctx.strokeStyle = 'red'
        ctx.lineWidth = 3

        // draw a red line
        ctx.beginPath()
        ctx.setLineDash([15, 15])
        ctx.moveTo(cx, yAxis.bottom)
        ctx.lineTo(cx, yAxis.top)
        ctx.stroke()
        ctx.restore() /// restore original state
      },

      afterDraw: chart => {
        var ctx = chart.chart.ctx
        var xAxis = chart.scales['x-axis-0']
        var yPos = xAxis.bottom
        const ticks = xAxis.ticks
          .map((x, index) => {
            if (x && x.includes(':')) { return { text: x, xPos: xAxis.getPixelForTick(index) } }
          })
          .filter(item => item !== undefined)

        const fontSize = this.getTicksFontSize() + 4

        /// expand with color, background etc.
        const drawTextBG = (ctx, txt, font, x, y) => {
          /// lets save current state as we make a lot of changes
          ctx.save()
          ctx.font = font
          ctx.textBaseline = 'bottom' /// draw text from top - makes life easier at the moment
          ctx.fillStyle = 'white' /// color for background
          var width = ctx.measureText(txt).width /// get width of text
          ctx.fillRect(x, y, width, 28) /// draw background rect assuming height of font
          ctx.fillStyle = 'blue' /// text color

          ctx.fillText(txt, x, y + 44 - fontSize) /// draw text on top

          ctx.restore() /// restore original state
        }

        for (var tick of ticks) {
          drawTextBG(ctx, tick.text, `bold ${fontSize}px Arial`, tick.xPos - 31, yPos - 30)
        }
      }
    })

    window.addEventListener('resize', (event) => {
      this.update()
    })

    this.renderChart(this.myChartData, this.myChartOptions, { responsive: true, maintainAspectRatio: false })
  },
  watch: {
    series () {
      this.update()
    },

    timeRange () {
      this.update()
    },
    adjustYAxis () {
      this.update()
    }
  },
  methods: {
    getContext2D () {
      return this.getContext('2d')
    },

    addFlags () {
      var ps = []
      for (var d of this.myChartData.datasets) {
        for (var i = 0; i < d.data.length - 1; i++) {
          if (!isNaN(d.data[i].y) && isNaN(d.data[i + 1].y)) {
            ps.push(d.data[i])
          }
        }
      }

      this.myChartData.datasets.push(
        {
          label: '_FLAGS',
          data: ps,
          borderColor: 'red',
          fill: false,
          pointRadius: 30,
          showLine: false,
          pointStyle: this.flagImage
        }
      )
    },

    isNarrow () {
      return this.$el.clientWidth < 400
    },

    getTicksFontSize () {
      return this.isNarrow() ? 12 : 18
    },

    update () {
      const opt = this.myChartOptions
      const xAxis = opt.scales.xAxes[0]
      const xTicks = xAxis.ticks
      xTicks.min = this.timeRange[0]
      xTicks.max = this.timeRange[1]

      const fontSize = this.getTicksFontSize()
      xTicks.fontSize = fontSize

      opt.tooltips.titleFontSize = fontSize - 2
      opt.tooltips.bodyFontSize = fontSize

      // Formating ticks
      const timespan = (xTicks.max - xTicks.min) / 1000
      var tickSecGap = 10
      if (timespan < 40) tickSecGap = 5
      if (timespan < 20) tickSecGap = 2
      if (timespan < 10) tickSecGap = 1
      if (this.isNarrow()) tickSecGap = tickSecGap * 2

      // console.log(xTicks.max - xTicks.min)
      xAxis.ticks.callback = function (value, index, values) {
        const date = new Date(values[index].value)
        const sec = date.getSeconds()
        if (sec === 0) {
          var h = date.getHours().toString()
          if (h.length < 2) h = '0' + h

          var m = date.getMinutes().toString()
          if (m.length < 2) m = '0' + m

          return `${h}:${m}`
        }
        return '' + date.getSeconds()
      }

      // DATA
      var nLayerSeries = new Map()
      this.myChartData.datasets = this.series.map(series => {
        const data = series.getDataSlice(xTicks.min, xTicks.max)

        if (!nLayerSeries.has(series.layerId)) {
          nLayerSeries.set(series.layerId, 0)
        }
        nLayerSeries.set(series.layerId, nLayerSeries.get(series.layerId) + 1)

        const thisLayerNSeries = nLayerSeries.get(series.layerId)
        const color = thisLayerNSeries < 2
          ? FSPModel.getLayerById(series.layerId).color
          : series.color

        const layer = FSPModel.getLayerById(series.layerId)
        const layerName = FSPModel.getLayerById(series.layerId).name
        return {
          label: layerName + ': ' + series.name,
          data: data,
          borderColor: color,
          fill: false,
          unit: layer.getUnits(series.name)
        }
      })

      this.myChartData.labels = []
      const minTimeMs = new Date(new Date(xTicks.min).setMilliseconds(0)).setSeconds(0)
      const maxTimeMs = new Date(xTicks.max).getTime()
      for (var t = minTimeMs; t < maxTimeMs; t += tickSecGap * 1000) {
        this.myChartData.labels.push(t)
      }

      // Y AXIS
      const yAxis = opt.scales.yAxes[0]
      const yTicks = yAxis.ticks
      yTicks.fontSize = fontSize

      yTicks.min = Number.POSITIVE_INFINITY
      yTicks.max = Number.NEGATIVE_INFINITY
      if (this.adjustYAxis) {
        for (const d of this.myChartData.datasets) {
          const data = d.data.filter(p => p.x >= xTicks.min && p.x <= xTicks.max)
          for (const p of data) {
            if (p.x >= xTicks.min && p.x <= xTicks.max) {
              if (!isNaN(p.y)) {
                yTicks.min = Math.min(yTicks.min, p.y)
                yTicks.max = Math.max(yTicks.max, p.y)
              }
            }
          }
        }
        const delta = 0.1 + 0.1 * (yTicks.max - yTicks.min)
        yTicks.min -= delta
        yTicks.max += delta
      } else {
        for (var series of this.series) {
          yTicks.min = Math.min(yTicks.min, series.minY)
          yTicks.max = Math.max(yTicks.max, series.maxY)
        }
      }

      yTicks.stepSize = (yTicks.max - yTicks.min) / 10.0

      // Flags
      if (this.myChartData.datasets.length > 0) {
        if (this.flagImage.src === '') {
          var myThis = this
          this.flagImage.onload = function () {
            myThis.addFlags()
            myThis.renderChart(myThis.myChartData, myThis.myChartOptions)
          }
          this.flagImage.src = require('../../assets/point.png')
        } else {
          this.addFlags()
        }
      }

      // this.$data._chart.update();
      this.renderChart(this.myChartData, this.myChartOptions)
    }
  }
}
