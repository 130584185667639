<template>
  <v-app style="height: 100%">
    <v-main style="height: 100%;">

      <LandingMenu v-if="landingMenuVisible" :showFileArea="landingMenuShowFileArea" :absolute="absolute"
        @onDropFileAreaError="onDropFileAreaError" @onEventLoaded="onEventLoaded" />

      <div class="appAndMenuContainer mb-safe">
        <div style="width:30px; min-width:30px; background-color: rgba(26, 50, 88, 1);">
          <img draggable="false" id="side_menu_img" :src="require('./assets/fsp_logo_vertical.png')" width="30"
            height="200" />
          <!-- <v-img max-width="30px" :src="require('./assets/fsp_logo_vertical.png')" /> -->

          <button v-if="!playingLive" class="collapsed-menu-button" @click="(fullScreen = !fullScreen)">
            <v-icon color="white" size="15px">
              {{ !fullScreen ? "mdi-square-outline" : "mdi-grid" }}
            </v-icon>
          </button>

          <button v-if="!playingLive" class="collapsed-menu-button" style="height: 150px;
                  position: absolute;
                  bottom: 0px;
                  left: 0px;" @click="(layerTableHidden = !layerTableHidden)">
            <div class="vertical-text">{{ layerTableHidden ? "SHOW LAYERS" : "HIDE LAYERS" }}</div>
          </button>

        </div>

        <div class="appContainer">

          <v-navigation-drawer v-model="drawer" id="side_menu" touchless permanent
            style="z-index: 999999 !important; position:absolute" :mini-variant.sync="miniMenu" mini-variant-width="0px">
            <div style="background-color: rgba(26, 50, 88, 1); height: 100%; width: 100%;">
              <v-img :src="require('./assets/fsp_logo_2.png')" max-height="80px"
                style="border-radius: 10px; background-color: rgba(26, 50, 88, 1);" />

              <v-expansion-panels class="mt-5">

                <v-expansion-panel class="mt-5"
                  @click.stop="landingMenuShowFileArea = false; landingMenuVisible = !landingMenuVisible">
                  <!-- header not expanding -->
                  <v-expansion-panel-header disable-icon-rotate style="min-height: 48px !important;">
                    Events
                    <template v-slot:actions>
                      <v-icon color="rgba(26, 50, 88, 1)">
                        mdi-sail-boat
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                </v-expansion-panel>

              </v-expansion-panels>

              <v-expansion-panels class="mt-5">

                <v-expansion-panel v-if="!playingLive">
                  <v-expansion-panel-header disable-icon-rotate>
                    Add Data
                    <template v-slot:actions>
                      <v-icon color="rgba(26, 50, 88, 1)">
                        mdi-file-document
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="height: 280px;">
                    <div style="height: 200px; margin-bottom: 10px;">
                      <drop-file-area @onFiles="onFilesSelected"/>
                    </div>

                    <v-btn tile width="100%" color="rgba(26, 50, 88, 1);" @click="removeAllLayers()">
                      <v-icon left> mdi-file-document-remove-outline </v-icon> Clear Project
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="!playingLive">
                  <v-expansion-panel-header disable-icon-rotate>
                    Screen
                    <template v-slot:actions>
                      <v-icon color="rgba(26, 50, 88, 1)"> mdi-view-grid-outline </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="background-color: rgba(26, 50, 88, 1); padding-top: 10px;">

                    <v-btn tile width="100%" color="rgba(26, 50, 88, 1);" @click="addView('video');"
                      :disabled="!canAddViews">
                      <v-icon left> mdi-video </v-icon> Add Video
                    </v-btn>

                    <v-btn class="mt-1" tile width="100%" color="rgba(26, 50, 88, 1);" @click="addView('photo');"
                      :disabled="!canAddViews">
                      <v-icon left> mdi-camera </v-icon> Add Photos
                    </v-btn>

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Interface Settings
                    <template v-slot:actions>
                      <v-icon color="rgba(26, 50, 88, 1)">
                        mdi-view-dashboard
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="!playingLive">
                      <v-switch dark v-model="fullScreen" label="Single View Mode" />
                      <v-switch dark v-model="layerTableHidden" label="Hidden Layer View" />
                      <v-btn v-if="!layerTableHidden && hasHiddenLayers()" tile width="100%" color="rgba(26, 50, 88, 1);"
                        @click="switchHiddenLayers();" style="margin-bottom: 5px;">
                        Show hidden layers
                      </v-btn>
                    </div>

                    <v-btn-toggle v-model="dataViewFormat" mandatory>
                      <v-btn>
                        <v-icon>mdi-format-horizontal-align-right</v-icon>
                      </v-btn>
                      <v-btn>
                        <v-icon>mdi-format-vertical-align-top</v-icon>
                      </v-btn>
                      <v-btn>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-btn-toggle>

                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Playback
                    <template v-slot:actions>
                      <v-icon color="rgba(26, 50, 88, 1)">
                        mdi-cog-play
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="white--text">
                    <p>Speed-Up:</p>
                    <v-slider dark v-model="playBackSpeed" step="1" min="0" max="3" ticks="always"
                      :tick-labels="['x1', 'x2', 'x5', 'x10']" />
                    <p>Refresh rate (seconds):</p>
                    <v-slider dark v-model="playBackRate" step="1" min="0" max="4" ticks="always"
                      :tick-labels="['1.0', '0.8', '0.6', '0.4', '0.2']" />
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>

              <v-btn class="ma-4" dark @click.stop="miniMenu = !miniMenu">
                <v-icon>mdi-chevron-left</v-icon>Close
              </v-btn>

            </div>
          </v-navigation-drawer>

          <div id="view_container" class="viewContainer_rightDataView">
            <div style="flex: 1 1 auto; background-color: transparent;">
              <dynamic-view-set ref="viewSet" @layoutChanged="onLayoutChanged" :fullScreen="fullScreen" />
            </div>
            <div id="data_view_container" class="dataViewContainer_vertical">
              <data-view :vertical="dataViewFormat == 0" />
            </div>
          </div>

          <div class="bottomContainer">
            <div class="bottomPanelContainer">
              <bottom-panel ref="bottomPanel" :isLive="playingLive" />
            </div>

          </div>
        </div>

      </div>

      <v-alert v-if="errorMessage != null" prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            Error 2: {{ errorMessage }}
          </v-col>
          <v-col class="shrink">
            <v-btn @click="errorMessage = null;">Accept</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-snackbar v-model="snackbar">
        Data is being loaded...
        <v-progress-linear color="white accent-4" indeterminate rounded height="6"></v-progress-linear>
      </v-snackbar>

    </v-main>
  </v-app>
</template>

<style scoped>
.collapsed-menu-button {
  width: 20px;
  height: 40px;
  background: #005a8d;
  border-radius: 5px;
  margin: 5px;
  color: white;
}

.vertical-text {
  display: inline-block;
  transform: rotate(270deg);
  width: 108px;
  margin: 0px;
  height: 112px;
}

::v-deep .v-application--wrap {
  min-height: 100%;
  /*!!!!*/
}

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99999999999999999999999999;
}

.theme--light.v-application {
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  height: 100%;
}

.v-expansion-panel-content {
  background-color: rgba(26, 50, 88, 1) !important;
  padding-top: 10px;
}

body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.bottomContainer {
  flex-basis: fit-content;
  display: inline-flex;
  background-color: transparent;
}

.bottomSideContainer {
  width: 0px;
  display: flex;
  flex-flow: column;
}

.bottomFileDropAreaContainer {
  flex: 1 1 100%;
  background-color: transparent;
  padding: 10px;
  flex-flow: vertical;
}

.appContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #323366, #162f54);
  max-width: calc(100% - 30px);
  padding: 5px;
}

.appAndMenuContainer {
  display: flex;
  flex: 1 0 auto;
  flex-flow: row;
  height: 100%;
  width: 100%;

  /* -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: linear-gradient(180deg, #323366, #162f54);
  padding: env(safe-area-inset-top, 20px)
  env(safe-area-inset-right, 20px)
  env(safe-area-inset-bottom, 20px)
  env(safe-area-inset-left, 20px); */
  /* padding-bottom: env(safe-area-inset-bottom, 60px); */
}

.header {
  flex: 0 1 40px;
  background-color: transparent;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dataView {
  flex: 0 1 130px;
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.bottomPanelContainer {
  flex: 0 1 100%;
  background-color: transparent;
}

.viewContainer_rightDataView {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  height: 100px;
}

.viewContainer_topDataView {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column-reverse;
  height: 100px;
}

.dataViewContainer_vertical {
  flex: 1 1 auto;
  background-color: transparent;
  flex-direction: column;
  display: flex;
  padding-bottom: 30px;
  max-width: 100px;
  max-height: 100%;
  padding-top: 5px;
}

.dataViewContainer_horizontal {
  flex: 1 1 auto;
  background-color: transparent;
  flex-direction: column;
  display: flex;

  padding-right: 5px;
  padding-left: 5px;

  margin-bottom: 10px;

  max-width: 100%;
  max-height: 110px;
}

.dataViewContainer_hidden {
  flex: 1 1 auto;
  background-color: transparent;
  flex-direction: column;
  display: flex;
  padding-bottom: 20px;

  max-width: 0px;
  max-height: 0px;
  display: none;
}
</style>

<script>
import BottomPanel from './components/BottomPanel.vue'
import DataView from './components/DataView.vue'
import DropFileArea from './components/DropFileArea.vue'
import DynamicViewSet from './components/DynamicViewSet.vue'
import { EventBus, FSPModel } from './Globals'
import { loadFSPDataFromString } from './components/FSPLayers/FSPLayerLoad.js'
import LandingMenu from './components/LandingMenu.vue'

export default {
  name: 'App',

  data: () => ({
    landingMenuVisible: true,
    landingMenuShowFileArea: true,
    eventMenuVisible: false,
    absolute: true,
    canAddViews: true,
    miniMenu: true,
    fullScreen: false,
    layerTableHidden: false,
    playBackRate: 4,
    playBackSpeed: 0,
    showingDropFileArea: false,
    drawer: true,
    errorMessage: null,
    snackbar: false,
    dataViewFormat: 1,
    playingLive: false
  }),

  components: {
    BottomPanel,
    DataView,
    DropFileArea,
    DynamicViewSet,
    LandingMenu
  },

  methods: {
    resize () {
    },

    getDefaultViews () {
      if (this.playingLive) {
        return [{ x: 0, y: 0, w: 1, h: 1, i: '0', static: false, type: 'map' }]
      } else {
        return [
          { x: 0, y: 0, w: 1, h: 1, i: '0', static: false, type: 'map' },
          { x: 1, y: 0, w: 1, h: 1, i: '1', static: false, type: 'plot' }
        ]
      }
    },

    addView (type) {
      this.$refs.viewSet.addView(type)
    },

    onLayoutChanged (layout) {
      this.canAddViews = layout.length < 4
    },

    removeAllLayers () {
      FSPModel.removeAllLayers()
    },

    switchHiddenLayers () {
      this.$refs.bottomPanel.switchHiddenLayers()
    },

    hasHiddenLayers () {
      if (!this.$refs.bottomPanel) {
        return false
      }
      return this.$refs.bottomPanel.hasHiddenLayers()
    },

    closeMenu () {
      this.drawer = !this.drawer
    },

    onDropFileAreaError (error) {
      if (error) {
        this.errorMessage = error
        setTimeout(() => {
          this.errorMessage = null
        }, 5000)
      }
    },

    onFilesSelected (files) {
      this.snackbar = true
      FSPModel.onNewFiles(files, (error) => {
        this.snackbar = false
        if (error) {
          this.errorMessage = error
          setTimeout(() => {
            this.errorMessage = null
          }, 5000)
        }
      })
    },

    adjustDataView () {
      const vc = document.getElementById('view_container')
      const dvc = document.getElementById('data_view_container')
      switch (this.dataViewFormat) {
        case 0:
          vc.className = 'viewContainer_rightDataView'
          dvc.className = 'dataViewContainer_vertical'
          break

        case 1:
          vc.className = 'viewContainer_topDataView'
          dvc.className = 'dataViewContainer_horizontal'
          break

        default:
          dvc.className = 'dataViewContainer_hidden'
      }
    },

    readURLParameters () {
      const url = new URL(window.location.href)
      const getParameters = new URLSearchParams(url.search)
      const linkToFSPData = getParameters.get('link')
      let fspDataName = getParameters.get('sportsman')
      if (linkToFSPData) {
        // Download the text file
        const xhr = new XMLHttpRequest()
        xhr.open('GET', linkToFSPData, true)
        xhr.responseType = 'blob'
        xhr.onload = function (e) {
          if (this.status === 200) {
            // get binary data as a response
            const blob = this.response
            const reader = new FileReader()
            reader.readAsText(blob)
            reader.onload = function (e) {
              const text = reader.result
              if (fspDataName === null) {
                fspDataName = 'LINKED FSP DATA'
              }
              loadFSPDataFromString(fspDataName, text, null)
            }
          }
        }
        xhr.send()
      }
    },

    onEventLoaded (eventData, isLiveEventLoaded) {
      this.playingLive = isLiveEventLoaded
    }

  },

  mounted () {
    if (this.playingLive) {
      this.layerTableHidden = true
      this.dataViewFormat = 0
    }

    this.readURLParameters()

    EventBus.$on(EventBus.LAYER_LOADED, () => {
      this.landingMenuVisible = FSPModel.layers.length === 0
    })
    EventBus.$on(EventBus.LAYER_REMOVED, () => {
      this.landingMenuVisible = FSPModel.layers.length === 0
    })

    const app = this
    window.addEventListener('click', function (e) {
      const btns = document.querySelectorAll('.collapsed-menu-button')
      for (const b of btns) {
        if (b === e.target || b.contains(e.target)) {
          return
        }
      }

      if (app.miniMenu) {
        app.miniMenu = e.clientX > 30
      } else {
        app.miniMenu = e.clientX > 256
      }
    })

    this.adjustDataView()
  },

  watch: {

    dataViewFormat () {
      this.adjustDataView()
    },

    layerTableHidden () {
      this.$refs.bottomPanel.setLayerTableVisible(!this.layerTableHidden)
    },

    playBackSpeed () {
      const su = [1, 2, 5, 10][this.playBackSpeed]
      FSPModel.clock.speedUpFactor = su
    },
    playBackRate () {
      const pbr = [1000, 800, 600, 400, 200][this.playBackRate]
      FSPModel.clock.deltaTimeMs = pbr
    },

    landingMenuVisible () {
      console.log(`landingMenuVisible: ${this.landingMenuVisible}`)
    },

    eventMenuVisible () {
      console.log(`eventMenuVisible: ${this.eventMenuVisible}`)
    },

    playingLive () {
      if (this.playingLive) {
        this.$refs.viewSet.closeAll()
        this.$refs.viewSet.addView('map')
        this.layerTableHidden = true
      } else {
        this.$refs.viewSet.closeAll()
        this.$refs.viewSet.addView('map')
        this.$refs.viewSet.addView('plot')
      }
    }
  }
}
</script>
