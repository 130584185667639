<template>
  <v-container class="ma-0 pa-0" color="transparent" style="width: 300px;">
    <v-row class="ma-0 pa-0" color="transparent">

      <v-btn x-small rounded elevation="2" color="#004a82" @click="dialog = true;">
        <div class="selectButton white--text">SELECT SOURCE ▼</div>
      </v-btn>
      <div class="layerListContainer" v-if="selectFSPDataLayers">
        <!-- <p v-if="eventName != null" class="eventName">{{eventName}}</p> -->
        <v-btn v-if="eventName != null" elevation="2" color="white" @click="showBoatsList = !showBoatsList" style="font-size: 18px; margin-left: 15px; margin-top: 10px; margin-bottom: -10px;">
          {{ eventName }}
        </v-btn>
        <p></p>
        <template v-if="showBoatsList" v-for="info in getSelectedLayers()">

          <v-menu v-model="info.menu" top :offset-x="true" min-width="300px" max-width="300px" :close-on-content-click="false" :close-on-click="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn plain color="black" dark v-bind="attrs" v-on="on" style="font-size: 18px; margin-left: -2px;">
                <span :style="'color:' + info.layer.color"> &#9673;</span> {{ info.layer.name }}
              </v-btn>
            </template>

            <v-card style="padding: 5px;">
              <v-card-title>{{ info.layer.name }}</v-card-title>
              <v-card-subtitle>Boat Icon</v-card-subtitle>
              <v-card-text style="height: 65px;">
                <v-img max-height="60px" max-width="60px" :src="info.icon"></v-img>
                <div style="width: 180px;height: 80px;margin: 0px;position: relative;top: -70px;left: 60px;">
                  <drop-file-area class="ml-5" @onFiles="onNewIconURL($event, info)" acceptedFiles=".png">
                  </drop-file-area>
                </div>
              </v-card-text>

              <v-card-subtitle>Path Color </v-card-subtitle>
              <v-card-text>
                <v-color-picker v-model="info.layer.color" dot-size="25" swatches-max-height="200"
                  v-on:update:color="onNewColor($event, info)"></v-color-picker>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="info.menu = false">
                  Close
                </v-btn>
              </v-card-actions> -->
            </v-card>

          </v-menu>

        </template>
      </div>

    </v-row>
    <v-row>

      <v-dialog v-model="dialog" width="500" style="z-index: 999999999" :dark="true">

        <v-card style="background-color: #1a5b78">
          <div class="dialog_title">SELECT DATA</div>

          <v-list style="background-color: transparent" shaped>
            <v-list-item-group dark :multiple="!selectVideoLayers">
              <v-list-item v-for=" item in getSelectableLayers()" :key="item.id" active-class="white--text"
                style="background-color: transparent">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox color="deep-blue accent-4" v-model="model" :value="item.id"
                    @change="clickedCheckbox(item.id)"></v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn v-if="!selectVideoLayers" color="white" text @click="selectAll()"> Select All </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="closeDialog()"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.eventName {
    font-size: 20px;
    margin-left: 15px;
    /* font-style: oblique; */
    font-weight: bolder;
    margin-bottom: -5px;
}

.layerListContainer {
  position: absolute;
  top: 20px;
  left: -15px;
}

#title {
  background: rgb(26, 50, 88);
  background: linear-gradient(90deg,
      rgba(26, 50, 88, 1) 0%,
      rgba(4, 33, 43, 1) 100%);
  font-size: 16px;
}

.selectButton {
  font-family: "Open Sans", sans-serif;
  font-size: 8px;
}

.layerLabel {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;

  /* background-color: rgba(128, 128, 128, 0.5); */
  background-color: transparent;
}

.option-chip {
  width: 100px;
}
</style>

<script>
import { EventBus, FSPModel } from '../Globals'
import { FSPData } from './FSPLayers/FSPData'
import { PhotoLayer } from './FSPLayers/PhotoLayer'
import { VideoLayer } from './FSPLayers/VideoLayer'
import DropFileArea from './DropFileArea.vue'

export default {
  name: 'SelectLayerWidget',

  components: { DropFileArea },

  props: {
    selectFSPDataLayers: Boolean,
    selectVideoLayers: Boolean,
    selectPhotoLayers: Boolean
  },

  data: () => ({
    dialog: false,
    model: [],
    menu: false,
    selectedLayers: [],
    eventName: null,
    showBoatsList: true
  }),

  watch: {
    model () {
      this.selectedLayers = this.getSelectedLayers()
      this.$forceUpdate()
    }
  },

  mounted () {
    this.eventName = FSPModel.eventName

    EventBus.$on(EventBus.LAYER_LOADED, (id) => {
      this.eventName = FSPModel.eventName
    })

    EventBus.$on(EventBus.LAYER_CHANGED, () => {
      this.selectedLayers = this.getSelectedLayers()
      this.$forceUpdate()
    })

    EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
      this.model = this.model.filter(i => i !== id)
    })
  },

  methods: {

    getSelectableLayers () {
      var layers = []
      if (this.selectVideoLayers) {
        layers = FSPModel.getLayersOfType(VideoLayer)
      }
      if (this.selectFSPDataLayers) {
        layers = [...layers, ...FSPModel.getLayersOfType(FSPData)]
      }
      if (this.selectPhotoLayers) {
        layers = [...layers, ...FSPModel.getLayersOfType(PhotoLayer)]
      }
      return layers
    },
    select (layerId) {
      debugger
      if (!this.model.includes(layerId)) {
        this.model.push(layerId)
      }
    },
    unselect (layerId) {
      debugger
      this.model = this.model.filter(id => id !== layerId)
    },

    onNewIconURL (files, info) {
      const boatIcon = (window.URL) ? window.URL.createObjectURL(files[0]) : window.webkitURL.createObjectURL(files[0])
      info.layer.changeData(info.layer.name, info.layer.minDate, boatIcon)
    },

    onNewColor (color, info) {
      const layer = FSPModel.getLayerById(info.layer.id)
      layer.color = color.hex
    },

    getSelectedLayers () {
      return this.model.map(id => {
        const layer = FSPModel.getLayerById(id)
        return {
          layer,
          menu: false,
          icon: layer.boatIcon
        }
      })
    },

    closeDialog () {
      this.dialog = false
      this.$emit('send-message', this.model)
    },

    selectAll () {
      this.model = this.getSelectableLayers().map(l => l.id)
    },

    clickedCheckbox (id) {
      if (this.selectVideoLayers) {
        this.model = [id]
      }
    }
  }
}
</script>
