<template>
    <div class="buttonAndVideoContainer">
        <select-layer-widget ref="select_layer_widget" @send-message="dialogChangedLayers" class="selectLayerWidget"
            selectPhotoLayers>
        </select-layer-widget>

        <v-icon v-if="layer == null" x-large color="white" class="noVideo">mdi-camera-off</v-icon>

        <div ref="videoContainer" class="background player">
            <v-img ref="player" width="100%" height="100%" :src="currentPhotoURL" contain/>
        </div>
    </div>
</template>

<style scoped>
.noVideo {
    top: 30%;
    z-index: 99999;
    text-align: center;
    display: block;
    width: 100%;
}

.muteButton {
    position: absolute;
    bottom: 35px;
    right: 15px;
    z-index: 99999;
    width: 30px;
    height: 40px;
    border-radius: 10px;
}

.buttonAndVideoContainer {
    height: 100%;
    background-color: black;
}

.player {
    object-fit: fill;
    margin: 0 auto;
    width: 100%
}

/* .background[data-v-8e4a65e8] {
    background-color: black;
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    height: 100%;
} */

.background {
    background-color: black;
    display: flex;
    position: absolute;
    top: 0px;
    width: 100%;
    height: calc(100% - 25px);
}

.selectLayerWidget {
    position: relative;
    top: 10px;
    left: 10px;
    z-index: 99999
}
</style>

<script>

import { EventBus, FSPModel, PAUSED, PLAY } from '../../Globals'
import { PhotoLayer } from '../FSPLayers/PhotoLayer';

import SelectLayerWidget from '../SelectLayerWidget.vue';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export default {
    name: 'PhotoView',
    components: {
        SelectLayerWidget
    },
    data: () => ({
        layer: null,
        currentPhotoURL: ""
    }),
    methods: {
        mountLayer(layer) {
            this.layer = layer;
            this.onTimeChanged(FSPModel.clock.playerTime);
            this.$refs.select_layer_widget.select(layer.id);
        },
        unmountLayer() {
            this.layer = null
            this.currentPhotoURL = ""
        },
        dialogChangedLayers(layers) {
            //console.log(layers)
            if (layers.length > 0) {
                let layer = FSPModel.getLayerById(layers[0]);
                console.log("Mounting photos " + layer.name)
                this.mountLayer(layer);
            }
        },
        onTimeChanged(t) {
            if (this.layer){
                let url = this.layer.getPhotoURLForTime(t);
                if (this.currentPhotoURL != url){
                    this.currentPhotoURL = url;
                }
            }
        }
    },
    mounted() {

        EventBus.$on(EventBus.TIME_CHANGED, (t) => {
            this.onTimeChanged(t);
        });

        EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
            if (this.layer){
                if (id == this.layer.id) {
                    this.unmountLayer();
                }
            }
        });

        EventBus.$on(EventBus.LAYER_CHANGED, (id) => {
            if (this.layer != null && this.layer.id == id) {
                this.onTimeChanged(FSPModel.clock.playerTime);
            }
        });

        //Mount any photo layer
        let pl = FSPModel.getLayersOfType(PhotoLayer)
        if (pl.length > 0){
            this.mountLayer(pl[0])
        }
    },

    destroyed() {
    },

    watch: {
    }
}
</script>
