<template>
  <v-container class="ma-0 pa-0" color="transparent">
    <v-row class="ma-0 pa-0" color="transparent">
      <v-dialog v-model="dialog" width="500" style="z-index: 999999999" :dark="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small rounded elevation="2" color="#004a82" v-bind="attrs" v-on="on" :disabled="buttonDisabled">
            <div class="selectButton white--text">SELECT SOURCE ▼</div>
          </v-btn>
        </template>

        <v-card style="background-color: #1a5b78">
          <div class="dialog_title">SELECT DATA</div>

          <div v-if="!selectingXY">
            <FSPDataLayerSeriesPicker :categories="categories" :isMultiple="true" name="main" @change="onMainPickerChange"/>
          </div>
          <div v-else>
            <v-chip label link class="ma-4" color="#004a82" text-color="white">X Axis</v-chip>
            <FSPDataLayerSeriesPicker :categories="categoriesX" :isMultiple="false" name="x"/>
            <v-chip label link class="ma-4" color="#004a82" text-color="white">Y Axis</v-chip>
            <FSPDataLayerSeriesPicker :categories="categoriesY" :isMultiple="false" name="y"/>
          </div>

          <div style="width: 100%; float: right">
            <v-checkbox class="ml-4" v-model="selectingXY" label="Comparative Plot" color="indigo darken-3" hide-details
              style="width: 50%; float:left;   margin-bottom: 15px;" />
            <v-select v-if="selectingXY" class="mt-2" dark dense v-model="groupSecs" :items="[0, 15, 30, 60, 120]"
              label="Data grouped by time" outlined width="50px" style="width: 30%; float:right;   margin-right: 15px; " />
          </div>

          <v-divider></v-divider>

          <v-select v-model="selectedLayerId" dark dense :items="getLayerNames()" label="Source" outlined width="50px" style="margin-left: 15px; margin-right: 15px; margin-bottom: -25px;"></v-select>
          <v-divider style="margin-top: 5px"></v-divider>
          <v-card-actions style="justify-content: flex-end;">
            <v-btn color="white" text @click="closeDialog()" style="margin-left: 10px;"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

#title {
  background: rgb(26, 50, 88);
  background: linear-gradient(90deg,
      rgba(26, 50, 88, 1) 0%,
      rgba(4, 33, 43, 1) 100%);
  font-size: 16px;
}

.selectButton {
  font-family: "Open Sans", sans-serif;
  font-size: 8px;
}

.layerLabel {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

/* .v-chip{
  width: 100px;
} */

.option-chip {
  width: 100px;
}

/* .v-chip-group{
  max-width:300px;
} */
</style>

<script>
import { EventBus, FSPModel } from '../Globals'
import { FSPData } from './FSPLayers/FSPData'
import FSPDataLayerSeriesPicker from './FSPDataLayerSeriesPicker.vue'

export default {

  components: {
    FSPDataLayerSeriesPicker
  },
  name: 'SelectButton',

  props: {
    selection: Object
  },

  data: () => ({
    dialog: false,
    selectedLayerId: -1,
    tab: null,
    categories: [],
    categoriesX: [],
    categoriesY: [],
    selectingXY: false,
    groupSecs: 0,
    buttonDisabled: true,
    currentSelection: {}
  }),

  mounted () {
    EventBus.$on(EventBus.LAYER_LOADED, () => {
      var ls = FSPModel.getLayersOfType(FSPData)
      if (ls.length === 1) {
        this.showSeriesForFirstLayer()
      }
      this.buttonDisabled = FSPModel.getLayersOfType(FSPData).length === 0
    })

    EventBus.$on(EventBus.LAYER_CHANGED, () => {
      this.$forceUpdate()
    })

    EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
      const layers = FSPModel.getLayersOfType(FSPData)
      if (id === this.selectedLayerId) {
        this.selectedLayerId = layers.length > 0 ? layers[0].id : -1
      }
      this.buttonDisabled = layers.length === 0
    })
  },

  watch: {
    dialog () {
      if (this.dialog) {
        if (FSPModel.getLayersOfType(FSPData).length > 1) { // If all sources available we set it by default
          this.selectedLayerId = -1
        }
        this.$nextTick(() => {
          this.markSelectedSeries()
        })
      }
    },

    selection () {
      this.currentSelection = this.selection
    },

    selectedLayerId () {
      console.log(`Selected layer ${this.selectedLayerId}`)
      this.showSeriesForSelectedLayer()
    },

    selectingXY () {
      if (this.selectingXY && this.selectedLayerId === -1) {
        this.selectedLayerId = FSPModel.getLayersOfType(FSPData)[0].id
      }
      this.$forceUpdate()
    }

  },

  methods: {
    markSelectedSeries () {
      if (this.currentSelection.timePlot) {
        for (const c of this.categories) {
          c.selected = []
          for (let i = 0; i < c.items.length; i++) {
            if (this.currentSelection.timePlot.some(serieDesc => c.layerId === serieDesc[0] && c.items[i] === serieDesc[1])) {
              c.selected.push(i)
            }
          }
        }
      } else {
        for (var cat of this.categories) {
          cat.selected = []
        }
      }

      if (this.currentSelection.comparisonPlot) {
        const plot = this.currentSelection.comparisonPlot
        for (const c of this.categoriesX) {
          c.selected = []
          for (let i = 0; i < c.items.length; i++) {
            if (c.items[i] === plot.x.item && c.layerId === plot.x.layerId) {
              c.selected.push()
            }
          }
        }

        for (var c of this.categoriesY) {
          c.selected = []
          for (let i = 0; i < c.items.length; i++) {
            if (c.items[i] === plot.y.item && c.layerId === plot.y.layerId) {
              c.selected.push()
            }
          }
        }
      } else {
        for (const c of this.categoriesX) {
          c.selected = []
        }

        for (const c of this.categoriesY) {
          c.selected = []
        }
      }
    },

    getLayerNames () {
      const ls = FSPModel.getLayersOfType(FSPData)
      const names = ls.map((d) => { return { text: d.name, value: d.id } })
      if (!this.selectingXY && names.length > 1) {
        names.push({ text: 'All Sources', value: -1 })
      }
      return names
    },

    showSeriesForFirstLayer () {
      var ls = FSPModel.getLayersOfType(FSPData)
      if (ls.length > 0) {
        const data = ls[0]
        this.selectedLayerId = data.id
      }
      this.$forceUpdate()
    },

    showSeriesForSelectedLayer () {
      this.categories = []
      this.categoriesX = []
      this.categoriesY = []
      const datas = this.selectedLayerId > -1
        ? [FSPModel.getFSPDataWithId(this.selectedLayerId)]
        : FSPModel.getLayersOfType(FSPData)

      function addCategory (list, cat, names, layerId) {
        if (!list.some(c => c.tab === cat)) {
          list.push({ tab: cat, items: [], selected: [], layerId: layerId })
        }

        const c = list.find(c => c.tab === cat)
        if (c) {
          c.items = [...new Set([...c.items, ...names])] // remove repeated
        }
      }

      for (const data of datas) {
        var cats = data.getSeriesCategories()
        for (const [cat, names] of cats) {
          addCategory(this.categories, cat, names, this.selectedLayerId)
          addCategory(this.categoriesX, cat, names, this.selectedLayerId)
          addCategory(this.categoriesY, cat, names, this.selectedLayerId)

          // this.categories.push({ tab: cat, items: names, selected: [], layerId: this.selectedLayerId })
          // this.categoriesX.push({ tab: cat, items: names, selected: [], layerId: this.selectedLayerId })
          // this.categoriesY.push({ tab: cat, items: names, selected: [], layerId: this.selectedLayerId })
        }
      }
      this.markSelectedSeries()
      this.$forceUpdate()
    },

    findFirstSelected (categories) {
      const cat = categories.find(c => typeof c.selected === 'number')
      if (cat === undefined) {
        return null
      }
      return { layerId: cat.layerId, item: cat.items[cat.selected] }
    },

    closeDialog () {
      this.dialog = false

      let newSelection = {}

      if (this.selectingXY) {
        var x = this.findFirstSelected(this.categoriesX)
        var y = this.findFirstSelected(this.categoriesY)
        newSelection = {
          comparisonPlot: { x: x, y: y, groupSecs: this.groupSecs }
        }
        if (x != null && y != null) {
          this.$emit('new-selection', newSelection)
        }
      } else {
        this.$emit('new-selection', this.currentSelection)
      }
    },

    onMainPickerChange (pickerName, categories) {
      const sel = this.currentSelection
      delete sel.comparisonPlot
      // Filtering out current layer
      sel.timePlot = sel.timePlot ? sel.timePlot.filter(s => s[0] !== categories[0].layerId) : []

      if (categories[0].layerId === -1) { // All layers
        const layers = FSPModel.getLayersOfType(FSPData)
        for (const cat of this.categories) {
          const ns = cat.selected.map((i) => cat.items[i])
          for (const name of ns) {
            for (const l of layers.filter(l => l.hasSeries(name))) {
              if (!sel.timePlot.find(s => s[0] === l.id && s[1] === name)) {
                sel.timePlot.push([l.id, name])
              }
            }
          }
        }
      } else {
        for (const cat of this.categories) {
          var ns = cat.selected.map((i) => [cat.layerId, cat.items[i]])
          sel.timePlot = sel.timePlot.concat(ns)
        }
      }
    }
  }

}
</script>
