<template>
  <div style="width: 100%; height:100%;">
    <!-- <v-btn class="grid-button" x-small @click="switchFullscreen()" elevation="10" color="#02568F" width="60px">
      <v-icon small color="white">{{ scrolling ? "mdi-view-list" : "mdi-grid" }}</v-icon>
    </v-btn> -->
    <div style="display:flex; width: 100%; height: 100%; flex-direction: row;">
      <div ref="grid" style="flex: 1 1 100%;">

        <grid-layout id="grid_layout" :layout.sync="layout" :col-num="nCol" :row-height="rowHeight"
          :is-draggable="draggable" :is-resizable="resizable" :vertical-compact="true" :preventCollision="true"
          :auto-size="false" class="grid" :margin="gridMargins">

          <grid-item v-for="item in layout" :key="item.i" :static="item.static" @move="moveEvent" :x="item.x"
            :y="item.y" :w="item.w" :h="item.h" :i="item.i" dragAllowFrom=".dragbar" class="grid-item" >

            <div v-if="item.type == 'map'" :class="draggable? 'grid-item-view-container' : 'grid-item-view-container-no-dragbar'" >
              <leaflet-map></leaflet-map>
            </div>

            <div v-if="item.type == 'plot'" :class="draggable? 'grid-item-view-container' : 'grid-item-view-container-no-dragbar'">
              <MultiLinePlot> </MultiLinePlot>
            </div>

            <div v-if="item.type == 'video'" :class="draggable? 'grid-item-view-container' : 'grid-item-view-container-no-dragbar'">
              <VideoView> </VideoView>
            </div>

            <div v-if="item.type == 'photo'" :class="draggable? 'grid-item-view-container' : 'grid-item-view-container-no-dragbar'">
              <PhotoView> </PhotoView>
            </div>

            <div v-if="draggable" class="dragbar">
              <v-icon class="icon" color="white">{{ 'mdi-drag-horizontal-variant' }}</v-icon>
              <v-icon v-if="item.type == 'video' || item.type == 'photo' " class="icon" color="white"
                @click="close(item.i)" style="top:0px;">
                mdi-close
              </v-icon>
            </div>

          </grid-item>
          <div id="makesureitscrolls"></div>
        </grid-layout>

      </div>
      <div v-if="scrolling" style="background-color:brown; flex: 0 0 20px;"></div>
    </div>
  </div>

</template>

<style scoped>
.grid {
  width: 100%;
  height: 100%;
  background-color: transparent;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  overflow-x: hidden;
  overflow-y: scroll;
}

.icon {
  margin: auto;
  position: absolute;
  right: 50%;
}

/*
.close-button {
  position: absolute;
} */

.grid-button {
  margin-left: 10px;
  margin-bottom: 5px;
}

.dragbar {
  background-color: rgba(26, 50, 88, 1);
  flex-basis: 20px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 25px;
  z-index: 999999;
}

.grid-item {
  flex-flow: column;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  scroll-snap-stop: always;
  scroll-snap-align: start;
}

.grid-item-view-container {
  flex-grow: 1;
  overflow: hidden;
  margin-bottom: 25px;
}

.grid-item-view-container-no-dragbar {
  flex-grow: 1;
  overflow: hidden;
}

/*
.scrollSnap {
  scroll-snap-type: y mandatory;
  margin-bottom: 10px;
  background-color: transparent;
  overflow: scroll;
  padding: 5px;
  padding-right: 20px;
}

.scrollSnap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px red;
}

.snap-stop-always {
  scroll-snap-stop: always;
  scroll-snap-align: start;
  scroll-margin: 30px;
}

.viewsContainer {
  display: inline-flex;
}

.viewsContainerBase {
  flex: 1 1 auto;
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.oneViewContainer {
  width: 100%;
  min-width: 5%;
  height: 100%;
  background-color: transparent;
  padding: 10px;

  scroll-snap-stop: always;
  scroll-snap-align: start;
  scroll-margin: 30px;
}

.cell {
  overflow: hidden;
  height: 100%;
  width: 100%;
} */
</style>

<script>
import LeafletMap from './Map/LeafletMap.vue'
import MultiLinePlot from './Plot/MultiLinePlot.vue'
import { GridLayout, GridItem } from 'vue-grid-layout'
import VideoView from './Video/VideoView.vue'
import PhotoView from './Photo/PhotoView.vue'

import { EventBus } from '../Globals'

import { VideoLayer } from './FSPLayers/VideoLayer'
import { PhotoLayer } from './FSPLayers/PhotoLayer'

export default {
  name: 'DynamicViewSet',

  props: {
    fullScreen: Boolean
  },

  data () {
    return {
      nCol: 2,
      rowHeight: 40,
      scrolling: false,
      draggable: true,
      resizable: false,
      index: 0,
      gridMargins: [5, 5],
      layout: [
        { x: 0, y: 0, w: 1, h: 1, i: '0', static: false, type: 'map' },
        { x: 1, y: 0, w: 1, h: 1, i: '1', static: false, type: 'plot' }
      ]
    }
  },

  components: {
    LeafletMap,
    MultiLinePlot,
    GridLayout,
    GridItem,
    VideoView,
    PhotoView
  },

  methods: {
    resize () {
      // alert("ok")
    },

    close (id) {
      this.layout = this.layout.filter(v => { return v.i !== id })
      this.$emit('layoutChanged', this.layout)
    },

    closeAll () {
      this.layout = []
      this.$emit('layoutChanged', this.layout)
    },

    getNRows () {
      return this.fullScreen ? this.layout.length : Math.ceil(this.layout.length / this.nCol)
    },

    updateGrid () {
      const grid = this.$refs.grid
      if (grid) {
        var gridChanged = false
        // Grid config
        const verticalScreen = grid.clientHeight > grid.clientWidth
        var newNCol = this.layout.length < 2 || verticalScreen || this.fullScreen ? 1 : 2
        if (this.nCol !== newNCol) {
          this.nCol = newNCol
          gridChanged = true
        }

        const nRows = this.getNRows()
        var newRowHeight = this.fullScreen ? grid.clientHeight : grid.clientHeight / nRows
        if (!this.fullScreen) {
          newRowHeight -= 11 // Padding
        }
        if (this.rowHeight !== newRowHeight) {
          this.rowHeight = newRowHeight
          gridChanged = true
        }

        // Full screen config
        var gridLayout = document.getElementById('grid_layout')
        gridLayout.style.overflowY = this.fullScreen ? 'scroll' : 'hidden'
        this.gridMargins = [this.fullScreen ? 15 : 5, 5]
        this.draggable = !this.fullScreen && this.layout.length > 1

        if (gridChanged) { // Cells position
          const cellIndex = (c) => c.x + c.y * this.nCol
          this.layout.sort((c0, c1) => cellIndex(c0) - cellIndex(c1))
          this.layout.forEach(cell => {
            const index = cellIndex(cell)
            cell.x = index % this.nCol
            cell.y = Math.floor(index / this.nCol)
          })
        }
        this.coverEmptySpace()
      }

      // Forcing the grid to readjust
      var x = document.getElementById('makesureitscrolls')
      x.style.width = x.style.width === '0px' ? '1px' : '0px'
    },

    getFirstEmptySpot () {
      const nRows = this.getNRows()
      for (var c = 0; c < this.nCol; c++) {
        for (var r = 0; r < nRows; r++) {
          if (this.layout.find(cell => cell.x === c && cell.y === r) === undefined) {
            return [c, r]
          }
        }
      }
      return undefined
    },

    coverEmptySpace () {
      const nRows = this.getNRows()

      for (var c of this.layout) {
        if (c.y >= nRows) {
          var e = this.getFirstEmptySpot()
          if (e) {
            [c.x, c.y] = e
          }
        }
      }

      const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
      for (var col = 0; col < this.nCol; col++) {
        const cells = this.layout.filter(c => c.x === col)
        cells.forEach(c => {
          c.x = clamp(c.x, 0, this.nCol - 1)
          c.y = clamp(c.y, 0, nRows - 1)
          c.h = c.y === 0 ? nRows - cells.length + 1 : 1
        })
      }
    },

    addView (type) {
      var x, y
      const id = Math.max(...this.layout.map(i => Number(i.i))) + 1
      if (this.nCol === 2) {
        const nextView = Math.max(...this.layout.map(i => i.y * 2 + i.x)) + 1
        x = nextView % 2
        y = Math.floor(nextView / 2)
      } else {
        const nextView = Math.max(...this.layout.map(i => i.y)) + 1
        x = 0
        y = nextView
      }
      this.layout.push({ x: x, y: y, w: 1, h: 1, i: id.toString(), static: false, type: type })
      this.$emit('layoutChanged', this.layout)
    },

    getNViews () {
      return this.layout.length
    },

    moveEvent: function (i, newX, newY) {
      var li = this.layout.find(c => c.i === i)

      for (var p = 0; p < this.layout.length; p++) {
        var lp = this.layout[p]
        if (i !== lp.i) {
          // Horizontal swapping
          if (newX >= lp.x && newX < lp.x + lp.w &&
            (li.y >= lp.y && li.y < lp.y + lp.h)) {
            [li.x, lp.x] = [lp.x, li.x]; // Swap
            [li.h, lp.h] = [lp.h, li.h] // Swap
          }
          // Vertical swapping
          if (newY >= lp.y && newY < lp.y + 1 && li.x === lp.x) {
            [li.y, lp.y] = [lp.y, li.y]; // Swap
            [li.h, lp.h] = [lp.h, li.h] // Swap
          }
        }
      }
      this.$emit('layoutChanged', this.layout)
    }
  },

  mounted () {
    setInterval(() => {
      this.updateGrid()
    }, 500)

    window.addEventListener('resize', () => {
      this.updateGrid()
    })

    // Adding views automatically
    EventBus.$on(EventBus.LAYER_LOADED, (layer) => {
      if (this.getNViews() < 4) {
        if (layer instanceof VideoLayer) {
          this.addView('video')
        }
        if (layer instanceof PhotoLayer) {
          this.addView('photo')
        }
      }
    })
  }
}
</script>
