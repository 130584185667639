import Vue from 'vue'
import { TimeFrameFSPLayer } from './components/FSPLayers/FSPLayer'
import { BuoyLayer } from './components/FSPLayers/BuoyLayer'
import { VideoLayer } from './components/FSPLayers/VideoLayer'
import { loadFSPLayer } from './components/FSPLayers/FSPLayerLoad'

export const EventBus = new Vue()
EventBus.LAYER_LOADED = 'newFSPDatasetLoaded'
EventBus.PLAYBACK_CHANGED = 'playbackChanged'
EventBus.TIME_CHANGED = 'timeChanged'
EventBus.LAYER_CHANGED = 'dataLayerChanged'
EventBus.LAYER_REMOVED = 'dataLayerRemoved'
EventBus.SPEEDUP_CHANGED = 'speedUpFactorChanged'

export const PLAY = 'play'
export const PAUSED = 'paused'

class Clock {
  #deltaTimeMs = 1000
  #speedUpFactor = 1
  #interval = null
  #playerTime = new Date()
  #playerMaxTime = null
  #playerMinTime = null

  #playingLive = false
  #liveDelayMs = 30000

  constructor (deltaTimeMs, speedUpFactor, currentPlayerTime) {
    this.#deltaTimeMs = deltaTimeMs || 1000 // Default to 1 second
    this.#speedUpFactor = speedUpFactor || 1 // Default to normal speed

    // Player time when starting the clock
    this.#playerTime = currentPlayerTime || new Date()
  }

  start () {
    if (!this.#interval) {
      const startTimeMs = Date.now()
      const playerTimeAtStartMs = this.playerTime.getTime()
      this.#interval = setInterval(() => {
        if (!this.#playingLive) {
          const elapsedTimeMs = Date.now() - startTimeMs
          const adjustedElapsedTimeMs = elapsedTimeMs * this.#speedUpFactor
          this.playerTime = new Date(playerTimeAtStartMs + adjustedElapsedTimeMs)

          const range = this.range
          if (this.playerTime.getTime() >= range.max) {
            this.stop()
          }
        } else {
          // Live
          this.playerTime = (new Date()).getTime() - this.#liveDelayMs
        }
      }, this.#deltaTimeMs)
      EventBus.$emit(EventBus.PLAYBACK_CHANGED, PLAY)
    }
  }

  get playerTime () {
    if (!(this.#playerTime instanceof Date)) {
      console.error('setPlayerTime expects a Date object')
      return null
    }
    return this.#playerTime
  }

  set playerTime (t) {
    // check t is a Date
    if (!(t instanceof Date)) {
      t = new Date(t)
    }
    if (isNaN(t.getTime())) {
      console.error('setPlayerTime expects a Date object or a timestamp')
      return
    }
    this.#playerTime = t
    if (!this.#playingLive) { // Don't clamp when playing live
      this.clampPlayerTimeToRange()
    } else {
      if ((this.#playerMinTime && this.#playerTime.getTime() < this.#playerMinTime) ||
        (this.#playerMaxTime && this.#playerTime.getTime() > this.#playerMaxTime)) {
        console.warn('Player time is out of range ', this.#playerTime, 'min', new Date(this.#playerMinTime), 'max', new Date(this.#playerMaxTime))
      }
    }
    EventBus.$emit(EventBus.TIME_CHANGED, this.playerTime)
  }

  clampPlayerTimeToRange () {
    if (this.#playerMinTime && this.#playerTime.getTime() < this.#playerMinTime) {
      this.#playerTime = new Date(this.#playerMinTime)
    } else if (this.#playerMaxTime && this.#playerTime.getTime() > this.#playerMaxTime) {
      this.#playerTime = new Date(this.#playerMaxTime)
    }
  }

  setPlayerTimeRange (min, max) {
    this.#playerMinTime = min.getTime()
    this.#playerMaxTime = max.getTime()
    this.clampPlayerTimeToRange()
    console.log(`Player time range set to ${new Date(this.#playerMinTime)} - ${new Date(this.#playerMaxTime)}}, Player time is now ${this.playerTime}`)
  }

  setPlayerTimeByRatio (ratio01) {
    this.playerTime = new Date((ratio01 * (this.#playerMaxTime - this.#playerMinTime)) + this.#playerMinTime)
  }

  getPlayerTimeRatio (date) {
    return (date.getTime() - this.#playerMinTime) / (this.#playerMaxTime - this.#playerMinTime)
  }

  getPlayerTimeAtRatio (ratio01) {
    return new Date((ratio01 * (this.#playerMaxTime - this.#playerMinTime)) + this.#playerMinTime)
  }

  stop () {
    if (this.#interval) {
      this.#playingLive = false
      clearInterval(this.#interval)
      this.#interval = null
      EventBus.$emit(EventBus.PLAYBACK_CHANGED, PAUSED)
    }
  }

  set speedUpFactor (factor) {
    this.#speedUpFactor = factor
    console.log(`Speed up factor set to ${this.#speedUpFactor}`)
    this.restartClock()
    EventBus.$emit(EventBus.SPEEDUP_CHANGED, factor)
  }

  set deltaTimeMs (deltaTime) {
    this.#deltaTimeMs = deltaTime
    console.log(`Delta time set to ${this.#deltaTimeMs} ms`)
    this.restartClock()
  }

  restartClock () {
    const t = this.playerTime
    this.stop()
    this.playerTime = t
    this.start()

    if (t !== this.playerTime) {
      console.error('Player time has changed after restart')
    }
  }

  get state () {
    return this.#interval !== null ? PLAY : PAUSED
  }

  playLive (delayMs = 10000) {
    console.log('Playing live')
    if (this.state === PLAY) {
      this.stop()
    }
    this.#playingLive = true
    this.#liveDelayMs = delayMs
    this.start()
  }

  get range () {
    return { min: this.#playerMinTime, max: this.#playerMaxTime }
  }

  get isPlayingLive () {
    return this.#playingLive && this.state === PLAY
  }
}

class FSPModelManager {
  layers = []
  clock = new Clock(200.0, 1.0, new Date())
  eventName = null
  liveVideoHTML = null // HTML element for live video

  addLayer (data) {
    const names = this.layers.map(x => x.name)
    while (names.includes(data.name)) {
      data.name += '_'
    }
    this.layers.push(data)
    if (data instanceof TimeFrameFSPLayer) {
      this.updateClockRange()
      if (this.layers.length === 1) {
        this.clock.playerTime = data.minDate
      }
    }

    EventBus.$emit(EventBus.LAYER_LOADED, data)
  }

  updateClockRange () {
    const ls = this.getLayersOfType(TimeFrameFSPLayer)
    const minDates = ls.map(x => x.minDate)
    const minDate = new Date(Math.min(...minDates))
    const maxDates = ls.map(x => x.maxDate)
    const maxDate = new Date(Math.max(...maxDates))
    this.clock.setPlayerTimeRange(minDate, maxDate)
  }

  getFSPDataWithId (id) {
    for (const d of this.layers) {
      if (d.id === id) {
        return d
      }
    }
    // console.error(`No layer found with id ${id}`)
    return null
  }

  removeFSPDataWithId (id) {
    this.layers = this.layers.filter(d => d.id !== id)
    console.log(`After removal we have ${this.layers.length} layers.`)
    this.updateClockRange()
    EventBus.$emit(EventBus.LAYER_REMOVED, id)
  }

  removeAllLayers () {
    const ids = this.layers.map(l => l.id)
    this.layers = []
    for (const id of ids) {
      EventBus.$emit(EventBus.LAYER_REMOVED, id)
    }
    console.log('All layers removed.')
  }

  getLayerDescriptors () {
    const descriptors = []
    for (const d of this.layers) {
      let min, max
      if (d instanceof TimeFrameFSPLayer) {
        min = this.clock.getPlayerTimeRatio(d.minDate) * 100
        max = this.clock.getPlayerTimeRatio(d.maxDate) * 100
      } else {
        min = 0
        max = 100
      }

      let type = 'DATA'
      if (d instanceof VideoLayer) {
        type = 'VIDEO'
      } else if (d instanceof BuoyLayer) {
        type = 'BUOYS'
      }

      descriptors.push({
        name: d.name,
        id: d.id,
        type: type,
        min_percentage: min,
        width_percentage: max - min,
        startTime: d.minDate,
        endTime: d.maxDate
      })
    }
    return descriptors
  }

  getLayerByName (name) {
    const x = this.layers.find(d => d.name === name)
    if (x === undefined) {
      console.error(`Can't find Layer ${name}`)
    }
    return x
  }

  getLayersOfType (t) {
    return this.layers.filter(l => l instanceof t)
  }

  getLayerById (id) {
    return this.layers.find(l => id === l.id)
  }

  onNewFiles (files, onFinished) {
    loadFSPLayer(files, onFinished)
  }

  onLayerChanged (id) {
    // check id correct
    const layer = this.getLayerById(id)
    if (layer === undefined) {
      throw new Error(`Layer with id ${id} not found`)
    }
    this.updateClockRange()
    EventBus.$emit(EventBus.LAYER_CHANGED, id)
  }
}

export const FSPModel = new FSPModelManager()
