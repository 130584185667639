import regression from 'regression'

export function approximate (dataPoints, order) {
  const xs = dataPoints.map(p => p.x)
  const minX = Math.min(...xs)
  const maxX = Math.max(...xs)

  const points = dataPoints.map(p => [p.x, p.y])
  const result = regression.polynomial(points, { order: order, precision: 10 })

  const regressionPoints = []
  const stepX = (maxX - minX) / 100
  for (let x = minX; x < maxX; x += stepX) {
    const p = result.predict(x)
    regressionPoints.push({ x: p[0], y: p[1] })
  }

  // const result = regression.linear([[0, 1], [32, 67], [12, 79]]);
  // const gradient = result.equation[0];
  // const yIntercept = result.equation[1];
  return regressionPoints
}
