<template>
  <div style="width: 100%; padding: 10px; background-color: transparent;">
    <v-sheet rounded="lg" class="mx-auto overflow-x-hidden cell" height="100%" width="100%"
      style="background-color: transparent;">
      <div id="panelContainer" class="panelContainer">

        <div class="top">

          <!-- PLAY BUTTON -->
          <v-btn rounded id="play_button" elevation="10" @click="playButtonClick()"
            :disabled="play_button_disabled" color="#02568F">
            <v-icon color="white">{{ play_button_icon }}</v-icon>
          </v-btn>

          <!-- SLIDER AND TIMES -->
          <div class="time-text-container">{{ clockText }}</div>
          <v-slider ref="timeSlider" v-model="sliderRatio" hint="Set the current time." :max="sliderMax" min="0" :disabled="sliderDisabled"
            color="white" thumb-color="white" track-color="white" class="ma-0 pa-0" hide-details
            @start="sliderIsDragged = true;" @end="sliderIsDragged = false;" style="margin-top: -3px !important;" />
          <div class="time-text-container">{{ clockText2 }}</div>

          <!-- PLAY LIVE BUTTON -->
          <v-btn v-if="isLive" rounded id="live_button" elevation="10" color="#02568F" style="width: 90px; height: 25px;"
          @click="toggleLive">
            <v-icon v-if="isPlayingLive()" color="red" class="icon">mdi-record</v-icon>
            <v-icon v-else color="white">mdi-record</v-icon>
            <span style="color: white; font-size: 12px; font-weight: bold;">LIVE</span>
          </v-btn>

          <!-- LIVE VIDEO BUTTON AND DIALOG -->
          <!-- <v-btn rounded v-if="hasLiveVideo()" color="#02568F" style="width: 40px; margin-left: 5px; height: 25px;" @click="liveVideoDialog = true" elevation="10">
            <v-icon color="white">mdi-broadcast</v-icon>
            <v-dialog v-model="liveVideoDialog" activator="parent" width="auto" style="z-index: 9999;" >
              <v-card>
                <div v-if="liveVideoDialog" id="liveVideoDialogContainer" v-html="getLiveVideoHTML()" style="width: 1000px; height: 600px;"></div>
                <v-card-actions>
                  <v-btn color="primary" block @click="liveVideoDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-btn> -->

        </div>
        <div style="height: 10px;"></div>

        <!-- LAYER TABLE -->
        <div class="top" v-for="item in layers" :key="item.key">
          <!-- BUTTONS -->
          <div class="layerButtonsContainer">
            <v-btn class="mx-1" fab dark x-small color="white" @click="hideLayer(item.id)" height="20px" width="20px">
              <v-icon color="black"> mdi-eye </v-icon>
            </v-btn>
            <v-btn class="mx-1" fab dark x-small color="white" @click="openDataInfoDialog(item.id)" height="20px"
              width="20px">
              <v-icon color="black"> mdi-information-variant </v-icon>
            </v-btn>
            <v-btn class="mx-1" fab dark x-small color="white" @click="removingLayerId = item.id; dialog = true;"
              height="20px" width="20px">
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </div>

          <data-layer-info-dialog ref="data_info_dialog" />
          <buoy-layer-info-dialog ref="buoy_layer_info_dialog" />
          <video-layer-info-dialog ref="video_layer_info_dialog" />
          <photo-layer-info-dialog ref="photo_layer_info_dialog" />

          <div class="time-text-container">{{ item.startTime }}</div>
          <!-- <v-slider ref="timeSlider" v-model="sliderRatio" hint="Set the current time." :max="sliderMax" min="0"
            color="white" thumb-color="white" track-color="white" class="ma-0 pa-0" hide-details
            @start="sliderIsDragged = true;" @end="sliderIsDragged = false;" style="margin-top: -3px !important;" /> -->

          <div class="layerBar" :layerId="item.id">
            <div class="layerBarLine">
              <div class="layerTag">
                {{ item.name                              }}
              </div>
              <div class="layerTag" style="z-index: 9; background-color: #1A3258;">
                {{ item.name                              }}
              </div>
            </div>

            <div :class="item.durationBarClass"
              :style="`width: ${item.width_percentage}%; left: ${item.min_percentage}%;`">
              <img :src="require('../assets/handle.png')" class="durationBarHandle" />
              <!-- {{ item.name}} -->
            </div>

          </div>
          <div class="time-text-container">{{ item.endTime }}</div>
        </div>

        <!-- <layer-table id="layer_table" ref="layer_table" class="layerTableContainer" style="height: 150px;" /> -->
      </div>
    </v-sheet>

    <!-- CLOSE DIALOG -->
    <v-dialog v-model="dialog" persistent max-width="290" :dark="true">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete Layer {{                              getLayerName(removingLayerId)                              }} ?
        </v-card-title>
        <v-card-text>This action can't be undone.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click="removeDataLayer(removingLayerId); dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style>

/* #liveVideoDialogContainer > :first-child {
  width:100%;
  height: 100%;
} */

.layerTag {
  color: white;
  position: absolute;
  top: -13px;
  background-color: #1a325887;
  left: 50%;
  padding-left: 5px;
  border-radius: 5px;
  height: 26px;
  padding-right: 5px;
  transform: translateX(-50%);
  z-index: 20;
}

.layerBarLine {
  background: white;
  width: 100%;
  height: 2px;
  position: relative;
  top: 50%;
}

.durationBarHandle {
  max-width: 15px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.durationBarFSPData {
  background-color: rgba(58, 245, 32, 1);
}

.durationBarVideo {
  background-color: rgb(255, 140, 0);
}

.durationBarBuoys {
  background-color: rgb(255, 0, 0);
}

.durationBarPhotos {
  background-color: yellowgreen;
}

.durationBar {
  position: relative;
  height: 25px;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
  top: -2px;
  z-index: 10;
}

.layerBar {
  background-color: transparent;
  flex: 0 1 100%;
  margin-left: 5px;
  margin-right: 5px;
  height: 25px;
  overflow: hidden;
}

:root{
  --play-button-width: 105px;
}

.layerButtonsContainer {
  min-width: var(--play-button-width);
  text-align: center;
}

.divider {
  border-width: 1px !important;
  border-color: black !important;
}

.button {
  background-color: #02568F;
  width: var(--play-button-width);
}

#play_button {
  height: 25px;
  background-color: #02568F;
  width: var(--play-button-width);
}

.input-file-container {
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
}

.small-btn-container {
  align-content: right;
  justify-content: right;
}

.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: none;
  position: relative;
  top: -5px;
  left: 5px;
}

.panelContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: transparent;
}

.top {
  flex: 0 1 40px;
  background-color: #1A3258;
  padding: 0px;
  display: inline-flex;
  flex-direction: row;
  padding: 10px;
  height: 10px;
  margin-top: -10px;
}

.layerTableContainer {
  flex: 1 1 auto;
  background-color: transparent;
  background-color: #1A3258;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
  overflow: hidden;
}

.play-button-container {
  height: 100%;
  width: 150px;
  background-color: transparent;
  padding: 10px;
  display: inline-flex;
}

.slider-button-container {
  height: 100%;
  flex: 1 1 auto;
  background-color: transparent;
}

.buttons-container {
  height: 100%;
  width: 100px;
  background-color: transparent;
  padding: 10px;
}

.centered {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slider-time-labels-container {
  display: flex;
}

.flex-expand-horizontally {
  flex: 1 1 auto;
  background-color: red;
}

.time-text-container {
  color: white;
  min-width: 80px;
  vertical-align: center !important;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 7px;
}

@media (max-width: 800px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .time-text-container {
    display: none;
  }

  :root{
    --play-button-width: 85px;
  }
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(198, 198, 198, 0.61) !important;
}

.icon {
  /* Establece el tamaño inicial del icono */
  transform: scale(1);

  /* Define la animación */
  animation-name: pulsate;
  animation-duration: 1s; /* Duración de la animación */
  animation-timing-function: ease-in-out; /* Función de temporización */
  animation-iteration-count: infinite; /* Repetir infinitamente */
}

@keyframes pulsate {
  0% {
    transform: scale(1); /* Tamaño inicial */
  }
  50% {
    transform: scale(1.2); /* Tamaño aumentado al 20% */
  }
  100% {
    transform: scale(1); /* Vuelve al tamaño normal */
  }
}

</style>

<script>
import { EventBus, FSPModel, PLAY } from '../Globals'
import { TimeFrameFSPLayer } from './FSPLayers/FSPLayer'
import DataLayerInfoDialog from './DataLayerInfoDialog.vue'
import BuoyLayerInfoDialog from './BuoyLayerInfoDialog.vue'
import VideoLayerInfoDialog from './VideoLayerInfoDialog.vue'

import { FSPData } from './FSPLayers/FSPData'
import { BuoyLayer } from './FSPLayers/BuoyLayer'
import { VideoLayer } from './FSPLayers/VideoLayer'

import { NO_TIME_STRING, formatTimeAsHH_MM_SS } from './TimeUtils.js'
import PhotoLayerInfoDialog from './PhotoLayerInfoDialog.vue'
import { PhotoLayer } from './FSPLayers/PhotoLayer'

export default {
  components: {
    DataLayerInfoDialog,
    BuoyLayerInfoDialog,
    VideoLayerInfoDialog,
    PhotoLayerInfoDialog
  },
  name: 'BottomPanel',

  props: {
    isLive: Boolean,
    data_example: String
  },

  data: () => ({
    snackbar: false,
    sliderRatio: 0,
    sliderDisabled: false,
    play_button_text: 'PLAY',
    clockText: NO_TIME_STRING,
    clockText2: NO_TIME_STRING,
    // liveVideoDialog: false,
    dataSetInfos: [],
    play_button_icon: 'mdi-play',
    speedUpFactor: 1,
    refreshRate: 1.0,
    play_button_disabled: true,
    sliderIsDragged: false,
    sliderMax: 1000,

    layers: [],
    hiddenLayerIds: [],
    dialog: false,
    removingLayerId: -1,
    layerTableVisible: true,
    layerLastKey: 0
  }),

  methods: {
    setUIPaused () {
      this.play_button_text = 'PLAY'
      this.play_button_icon = 'mdi-play'
    },
    pause () {
      FSPModel.clock.stop()
      this.setUIPaused()
    },
    setUIPlaying () {
      this.play_button_text = 'PAUSE'
      this.play_button_icon = 'mdi-pause'
    },
    play () {
      this.setUIPlaying()
      FSPModel.clock.start()
    },
    playButtonClick () {
      if (FSPModel.clock.state === PLAY) {
        this.pause()
      } else {
        this.play()
      }
    },
    onLayerChanged () {
      // this.pause()
      this.updateSlider(FSPModel.clock.range.min)
      const ls = FSPModel.getLayersOfType(TimeFrameFSPLayer)
      this.play_button_disabled = ls.length < 1
    },
    updateSlider (t) {
      if (t != null) {
        if (FSPModel.clock.isPlayingLive) {
          this.sliderDisabled = true
          this.sliderRatio = this.sliderMax
        } else {
          this.sliderDisabled = false
          if (!this.sliderIsDragged) {
            this.sliderRatio = FSPModel.clock.getPlayerTimeRatio(new Date(t)) * this.sliderMax
          }
        }
        const clockRange = FSPModel.clock.range
        this.clockText = formatTimeAsHH_MM_SS(this.isPlayingLive() ? clockRange.max : t)
        this.clockText2 = formatTimeAsHH_MM_SS(clockRange.max)
      }
    },

    setLayerTableVisible (v) {
      this.layerTableVisible = v
      this.updateLayerTable()
    },

    switchHiddenLayers () {
      this.hiddenLayerIds = []
      this.updateLayerTable()
    },

    hasHiddenLayers () {
      return this.hiddenLayerIds.length > 0
    },

    updateLayerTable () {
      if (this.layerTableVisible) {
        this.layers = FSPModel.getLayerDescriptors()
        this.layers = this.layers.filter(l => !this.hiddenLayerIds.includes(l.id))

        for (const l of this.layers) {
          l.startTime = formatTimeAsHH_MM_SS(l.startTime)
          l.endTime = formatTimeAsHH_MM_SS(l.endTime)

          const dbClasses = new Map([
            ['DATA', 'durationBarFSPData'],
            ['VIDEO', 'durationBarVideo'],
            ['BUOYS', 'durationBarBuoys'],
            ['PHOTOS', 'durationBarPhotos']
          ])
          l.durationBarClass = 'durationBar ' + dbClasses.get(l.type)
          l.key = this.layerLastKey
          this.layerLastKey++
        }
      } else {
        this.layers = []
      }

      this.$nextTick(() => {
        const bars = [...document.getElementsByClassName('layerBar')]
        for (const b of bars) {
          this.dragElement(b)
        }
      })
    },

    getLayerName (id) {
      const layer = FSPModel.getLayerById(id)
      return layer ? layer.name : ''
    },
    removeDataLayer (layerId) {
      FSPModel.removeFSPDataWithId(layerId)
    },
    openDataInfoDialog (datasetId) {
      const layer = FSPModel.getLayerById(datasetId)
      if (layer instanceof FSPData) {
        const diag = this.$refs.data_info_dialog[0]
        diag.openDialog(datasetId)
      } else if (layer instanceof BuoyLayer) {
        const diag = this.$refs.buoy_layer_info_dialog[0]
        diag.openDialog(datasetId)
      } else if (layer instanceof VideoLayer) {
        const diag = this.$refs.video_layer_info_dialog[0]
        diag.openDialog(datasetId)
      } else if (layer instanceof PhotoLayer) {
        const diag = this.$refs.photo_layer_info_dialog[0]
        diag.openDialog(datasetId)
      }
    },
    hideLayer (id) {
      this.hiddenLayerIds.push(id)
      this.updateLayerTable()
      console.log('hidden layers ' + this.hiddenLayerIds)
    },

    dragElement (elmnt) {
      let currentRatio = 0
      elmnt.onmousedown = dragMouseDown
      const vueComponent = this

      function dragMouseDown (e) {
        e = e || window.event
        e.preventDefault()
        document.onmouseup = closeDragElement
        document.onmousemove = elementDrag

        const rect = elmnt.getBoundingClientRect()
        currentRatio = (e.clientX - rect.x) / rect.width
      }

      function elementDrag (e) {
        e = e || window.event
        e.preventDefault()

        const rect = elmnt.getBoundingClientRect()
        currentRatio = (e.clientX - rect.x) / rect.width
        const durationBar = elmnt.getElementsByClassName('durationBar')[0]
        durationBar.style.left = (e.clientX - rect.x) + 'px'
      }

      function closeDragElement () {
        document.onmouseup = null
        document.onmousemove = null

        // Change Layer
        vueComponent.$nextTick(() => {
          vueComponent.layers = []
          const layerId = parseInt(elmnt.attributes.layerid.value)

          const layer = FSPModel.getLayerById(layerId)
          const layerMin = FSPModel.clock.getPlayerTimeAtRatio(currentRatio)
          if (layer instanceof TimeFrameFSPLayer) {
            layer.minDate = layerMin
          }
        })
      }
    },

    toggleLive () {
      console.log('toggle live')
      if (FSPModel.clock.isPlayingLive) {
        this.sliderDisabled = true
        FSPModel.clock.stop()
        FSPModel.clock.playerTime = FSPModel.clock.range.min
      } else {
        FSPModel.clock.playLive()
      }
    },

    isPlayingLive () {
      return FSPModel.clock.isPlayingLive
    }

    // getLiveVideoHTML () {
    //   return FSPModel.liveVideoHTML
    // },

    // hasLiveVideo () {
    //   return FSPModel.liveVideoHTML != null
    // }
  },

  mounted () {
    setInterval(() => {
      if (this.sliderIsDragged) {
        const state = FSPModel.clock.state
        const ratio = this.sliderRatio / this.sliderMax
        FSPModel.clock.stop()
        FSPModel.clock.setPlayerTimeByRatio(ratio)
        if (state === PLAY) {
          FSPModel.clock.start()
        }
      }
    }, 100)

    EventBus.$on(EventBus.TIME_CHANGED, (t) => {
      this.updateSlider(t)
    })

    EventBus.$on(EventBus.LAYER_LOADED, () => {
      this.onLayerChanged()
      this.updateLayerTable()
    })

    EventBus.$on(EventBus.LAYER_REMOVED, () => {
      this.onLayerChanged()
      this.updateLayerTable()
    })

    EventBus.$on(EventBus.LAYER_CHANGED, () => {
      this.updateLayerTable()
      this.onLayerChanged()
      this.updateSlider()
    })

    EventBus.$on(EventBus.PLAYBACK_CHANGED, (state) => {
      if (state === PLAY) {
        this.setUIPlaying()
      } else {
        this.setUIPaused()
      }
    })
  }
}
</script>
