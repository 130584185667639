import L from 'leaflet'

class Buoy {
  constructor (layerId, name, positions, map) {
    this.layerId = layerId
    this.name = name
    this.positionTimeSeries = positions // [{pos:, time:}]
    this.map = map

    this.marker = null
    this.currentPositionIndex = 0
  }

  getPosition (time) {
    if (this.positionTimeSeries.at(0).time > time) {
      return this.positionTimeSeries.at(0).pos
    }

    if (this.positionTimeSeries.at(-1).time < time) {
      return this.positionTimeSeries.at(-1).pos
    }

    if (this.positionTimeSeries.length === 1) {
      return this.positionTimeSeries.at(0).pos
    }

    for (var i = 0; i < this.positionTimeSeries.length; i++) {
      if (this.positionTimeSeries[this.currentPositionIndex].time <= time &&
        time <= this.positionTimeSeries[this.currentPositionIndex + 1].time) {
        return this.positionTimeSeries.at(this.currentPositionIndex).pos
      }
      this.currentPositionIndex = (this.currentPositionIndex + 1) % this.positionTimeSeries.length
    }
  }

  draw (time) {
    const pos = this.getPosition(time)
    if (this.marker == null) {
      let iconPath
      const n = this.name.toUpperCase()
      if (n.includes('COMMITTEE') ||
        n.includes('PIN') ||
        n.includes('DOWNWIND')) {
        iconPath = require('../../assets/orange_icon.png')
      } else if (n.includes('UPWIND') ||
        n.includes('OFFSET')) {
        iconPath = require('../../assets/yellow_icon.png')
      } else if (n.includes('INSIDE')) {
        iconPath = require('../../assets/red_icon.png')
      } else if (n.includes('FINISH GATE')) {
        iconPath = require('../../assets/blue_icon.png')
      } else if (n.includes('FINISH BUOY')) {
        iconPath = require('../../assets/white_icon.png')
      } else {
        iconPath = require('../../assets/green_icon.png')
      }

      const icon = L.icon({
        iconUrl: iconPath,
        iconSize: [20, 20],
        iconAnchor: [10, 10]
        // shadowUrl: "Datos/marker-shadow.png",
        // shadowSize: [35, 50],
        // shadowAnchor: [0, 55],
        // popupAnchor: [0, -40]
      })

      this.marker = L.marker(pos, {
        rotationAngle: 0,
        rotationOrigin: 'center',
        icon: icon,
        draggable: false
      })

      this.marker.bindTooltip(this.name, {
        permanent: false,
        direction: 'right'
      })
      this.marker.addTo(this.map)
    } else {
      this.marker.setLatLng(pos)
    }
  }

  clear () {
    if (this.marker != null) {
      this.map.removeLayer(this.marker)
      this.marker = null
    }
  }
}

export { Buoy }
