import { FSPModel } from '../../Globals'

let layerSessionIdCounter = 0

export class FSPLayer {
  #name = ''
  #id = 0

  constructor (name) {
    this.#name = name
    this.#id = layerSessionIdCounter++
  }

  notifyChange () {
    FSPModel.onLayerChanged(this.id)
  }

  set name (name) {
    this.#name = name
    this.notifyChange()
  }

  get name () {
    return this.#name
  }

  get id () {
    return this.#id
  }
}

export class TimeFrameFSPLayer extends FSPLayer {
  get minDate () {
    throw new Error('Not implemented')
  }

  get maxDate () {
    throw new Error('Not implemented')
  }

  set minDate (date) {
    throw new Error('Not implemented')
  }
}
