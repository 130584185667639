<template>
    <div>
        <v-tabs v-model="tab" background-color="primary" dark :grow="true">
            <v-tab v-for="item in categories" :key="item.tab">
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: transparent">
            <v-tab-item v-for="item in categories" :key="item.tab + name">
                <v-chip-group v-model="item.selected" column :multiple="isMultiple" class="ma-4" style="max-width=350px" @change="onChange">
                    <v-chip class="option-chip" filter outlined label v-for="n in item.items" :key="n" color="white">
                        {{ n }} >
                    </v-chip>
                </v-chip-group>
            </v-tab-item>
        </v-tabs-items>
    </div>

</template>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.option-chip {
    width: 100px;
}

</style>
<style>

.v-tabs-bar {
    border-radius: inherit;
    height: 48px;
    width: 100%;
}

</style>

<script>
import { emit } from 'process';

export default {
  name: 'FSPDataLayerSeriesPicker',

  props: [
    'categories',
    'isMultiple',
    'name'
  ],

  data: () => ({
    tab: null
  }),

  mounted () {
  },

  methods: {
    onChange () {
      if (!this.isMultiple) {
        for (var i = 0; i < this.categories.length; i++) {
          if (i != this.tab) {
            this.categories[i].selected = []
          }
        }
      }
      this.$emit("change", this.name, this.categories);
    }
  }
}
</script>
