<template>
  <v-dialog v-model="info_dialog" width="500" style="z-index: 999999999" :dark="true">
    <!-- <v-card class="text-white" style="background-color: #112b41">
      <center>
        <v-chip class="ma-2" color="primary"> LAYER SETTINGS </v-chip>
      </center> -->

    <v-card style="background-color: #154b65;">
      <div class="dialog_title">BUOY LAYER SETTINGS</div>

      <v-container class="white--text">
        <v-row class="ma-5">
          <v-text-field clearable dark label="Layer Name" hide-details="auto" v-model="name"></v-text-field>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="saveChanges(); info_dialog = false;"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.v-label {
  color: white !important;
}

.v-input input {
  max-height: 32px;
  color: white !important;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot::before {
  border-color: rgba(255, 255, 255, 255) !important;
}
</style>

<script>
import { FSPModel, EventBus } from '../Globals'
import { BuoyLayer } from './FSPLayers/BuoyLayer'

export default {
  name: 'BuoyLayerInfoDialog',

  components: {},

  data: () => ({
    layers: [],
    info_dialog: false,
    name: 'ok',
    layerId: null
  }),

  mounted () { },

  methods: {
    openDialog (datasetId) {
      const fspData = FSPModel.getFSPDataWithId(datasetId)
      this.layerId = fspData.id
      if (fspData != null) {
        this.info_dialog = true
        this.name = fspData.name
      }
    },

    saveChanges () {
      const layer = FSPModel.getLayerById(this.layerId)
      if (layer instanceof BuoyLayer) {
        layer.name = this.name
      }

      // TODO OVERLAY PROBLEM PATCH
      const overlay = document.getElementsByClassName('v-overlay')
      overlay[0].remove()
    }
  },

  props: {
    title: String,
    value: String
  }
}
</script>
