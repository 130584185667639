import L from 'leaflet'
import { FSPModel } from '../../Globals'
import { lerp, interpolateAngles } from '../MathUtils'
import { getTintedIcon } from '../ColorUtils'

const TRAIL_UPDATE_TIME_DELTA = 0

var css = document.createElement('style')
// eslint-disable-next-line no-multi-str
css.innerHTML = '.boat-label { \
  display: inline-block; \
  color: black; \
  font-size: 18px; \
  font-weight: normal; \
  white-space: nowrap; \
  background-color: white; \
  min-width: fit-content; \
  min-height: 35px; \
  padding: 5px; \
  border-radius: 10px; \
  border-color: black; \
  border-width: 1px; \
  border-style: solid; \
  }'
document.body.appendChild(css)

function getTextWidth(text, font) {
  // Create a temporary element
  const element = document.createElement('span');
  element.style.visibility = 'hidden';
  element.style.position = 'absolute';
  element.style.whiteSpace = 'nowrap';
  element.style.font = font; // Set the font to match the target element

  // Set the text content
  element.textContent = text;

  // Append the element to the body
  document.body.appendChild(element);

  // Get the width
  const width = element.offsetWidth;

  // Remove the element from the body
  document.body.removeChild(element);

  return width;
}

class BoatPath {
  layer = null
  map = null
  routeBehind = null
  routeAhead = null
  pathLength = NaN
  boatMarker = null
  currentPathData = null
  colorParameter = null
  #routeUpdateTime = 0
  #constructingMarker = false
  #toolTipPermanent = false
  showLabel = true

  mapLowerPaneName = 'boat_lower_path'
  mapUpperPaneName = 'boat_upper_path'

  constructor(layer, toolTipPermanent, map) {
    this.layer = layer
    this.map = map
    this.#toolTipPermanent = toolTipPermanent

    if (this.map.getPane(this.mapLowerPaneName) === undefined) {
      const pane = this.map.createPane(this.mapLowerPaneName)
      pane.style.zIndex = 300
    }

    this.mapUpperPaneName += this.layer.id
    if (this.map.getPane(this.mapUpperPaneName) === undefined) {
      const pane = this.map.createPane(this.mapUpperPaneName)
      pane.style.zIndex = 301 + this.layer.id
    }
  }

  centerMapOnPathStart() {
    if (this.layer.route.length > 0) {
      this.map.panTo(this.layer.route[0])
    }
  }

  setPathLength(v) {
    this.pathLength = Math.round(v)
    this.#routeUpdateTime = 0
    this.draw(FSPModel.clock.playerTime)
  }

  updatePathData(time) {
    if (isNaN(time) || this.layer.route.length === 0) {
      return null
    }

    this.currentPathData = { behindSlice: null, aheadSlice: null, currentPosition: null }
    const pd = this.currentPathData

    const fullPath = isNaN(this.pathLength)
    const pointCount = this.layer.route.length
    if (time <= this.layer.route[0].time) { // Before beginning
      pd.aheadSlice = [0, fullPath ? pointCount : Math.min(pointCount, this.pathLength)]
      pd.currentPosition = this.layer.route.at(0)
    } else if (time >= this.layer.route.at(-1).time) { // After end
      pd.behindSlice = [fullPath ? 0 : Math.max(0, pointCount - this.pathLength), pointCount]
      pd.currentPosition = this.layer.route.at(-1)
    } else {
      var index = this.layer.route.findIndex((p) => p.time > time) - 1

      pd.behindSlice = [fullPath ? 0 : Math.max(0, index - this.pathLength), index]
      pd.aheadSlice = [index, fullPath ? pointCount : Math.min(pointCount, index + this.pathLength)]

      if (index < this.layer.route.length - 1) {
        const t0 = this.layer.route[index].time
        const t1 = this.layer.route[index + 1].time
        if (t0 < t1) {
          const r = (time.getTime() - t0) / (t1 - t0)
          const p0 = this.layer.route[index]
          const p1 = this.layer.route[index + 1]

          pd.currentPosition = new L.LatLng(lerp(p0.lat, p1.lat, r), lerp(p0.lng, p1.lng, r))
          pd.currentPosition.time = time
          pd.currentPosition.direction = interpolateAngles(p0.direction, p1.direction, r)

          // console.log(`${this.name} -> Time ${pd.currentPosition.time} Direction ${pd.currentPosition.direction}
          // -> p0 direction ${p0.direction} p1 direction ${p1.direction} r ${r}`)
        }
      }

      if (pd.currentPosition == null) {
        pd.currentPosition = this.layer.route.at(-1)
      }

      // console.log("Boat path at index " + index);
    }
  }

  setTrailType(type) {
    this.#routeUpdateTime = -1
    this.colorParameter = type === 'Path' ? null
      : this.getParameterRelativeDeviation(type)
    if (this.colorParameter) {
      this.colorParameter = this.colorParameter.map(p => {
        if (p < 0.80) return '#808080'
        if (p < 0.85) return '#d80819'
        if (p < 0.90) return '#ffa500'
        if (p < 0.95) return '#f2dc0b'
        if (p < 1.00) return '#55a81f'
        if (p < 1.05) return '#2db8b8'
        if (p < 1.10) return '#1f368c'
        return '#361c59'
      })
    }
  }

  drawLabel(pos, time) {
    debugger
    if (!this.showLabel){
      if (this.labelMarker) {
        this.removeFromMap(this.labelMarker)
        this.labelMarker = null
      }
      return
    }
    const boatSpeed = this.layer.series.getRowByIndex(this.layer.getRowForTime(time)).BS

    let text = `${this.layer.name}: ${boatSpeed.toFixed(1)} kn`
    const font = 'bold 18px Arial';
    const width = getTextWidth(text, font);
    text = `${this.layer.name}: <b> ${boatSpeed.toFixed(1)} kn </b>`

    // Label
    if (this.labelMarker) {
      this.labelMarker.setLatLng(pos)
      this.labelMarker.getElement().innerHTML = text
    } else {
      this.labelMarker = L.marker([pos.lat, pos.lng], {
        icon: L.divIcon({
          className: 'boat-label',
          html: text,
          iconSize: [width, 34],
          iconAnchor: [width/2 + 5, -20]
        })
      })
      this.labelMarker.addTo(this.map)
    }
  }

  draw(time) {
    if (time == null) return

    this.updatePathData(time)
    if (this.currentPathData == null) {
      return
    } else {
      // console.log('P: ', this.currentPathData.currentPosition.lat, this.currentPathData.currentPosition.lng)
    }
    const timeMs = time.getTime()
    if (Math.abs(this.#routeUpdateTime - timeMs) > TRAIL_UPDATE_TIME_DELTA) {
      this.#routeUpdateTime = timeMs
      if (this.colorParameter) {
        this.drawParametricPath()
      } else {
        this.drawNormalRoute()
      }
    }

    // Marker
    if (this.boatMarker != null && this.layer.boatIcon !== this.boatMarker.getIcon().imageURL) {
      this.removeFromMap(this.boatMarker)
      this.boatMarker = null
    }

    const pos = this.currentPathData.currentPosition
    if (this.boatMarker == null && !this.#constructingMarker) {
      const thisPath = this
      this.#constructingMarker = true
      getTintedIcon(this.layer.boatIcon, this.layer.color, 0.6, (iconImg) => {
        const icon = L.icon({
          iconUrl: iconImg, // require("../../assets/boat_marker.png"),
          iconSize: [40, 40],
          iconAnchor: [20, 20]
          // shadowUrl: "Datos/marker-shadow.png",
          // shadowSize: [35, 50],
          // shadowAnchor: [0, 55],
          // popupAnchor: [0, -40]
        })
        icon.imageURL = thisPath.layer.boatIcon

        thisPath.boatMarker = L.marker([pos.lat, pos.lng], {
          rotationAngle: 0,
          rotationOrigin: 'center',
          icon: icon,
          draggable: true
        })

        this.createTooltip(this.#toolTipPermanent)

        thisPath.boatMarker.addTo(this.map)
        console.log('Added boat marker')
        thisPath.boatMarker.on('dragstart', function () {
          FSPModel.clock.stop()
        })
        thisPath.boatMarker.on('drag', function (event) {
          var marker = event.target
          var position = marker.getLatLng()
          thisPath.relocate(position)
        })
        thisPath.boatMarker.setRotationAngle(pos.direction)
        thisPath.boatMarker.setLatLng(pos)
        thisPath.boatMarker.iconURL = thisPath.layer.boatIcon // Storing data in marker
        thisPath.boatMarker.color = thisPath.layer.color // Storing data in marker
        thisPath.#constructingMarker = false

        // Label
        thisPath.drawLabel(pos, time)
      })
    } else {
      if (this.boatMarker) {
        this.boatMarker.setRotationAngle(pos.direction)
        this.boatMarker.setLatLng(pos)
      }

      this.drawLabel(pos, time)
    }
  }

  createTooltip(permanent) {
    this.#toolTipPermanent = permanent
    this.boatMarker.unbindTooltip()
    this.boatMarker.bindTooltip(this.layer.name, {
      permanent: this.#toolTipPermanent,
      direction: 'right'
    })
  }

  switchLabel(enabled) {
    this.showLabel = enabled
    this.draw(FSPModel.clock.playerTime)
  }

  getLocation() {
    return this.currentPathData.currentPosition
  }

  drawNormalRoute() {
    try {
      this.removeFromMap(this.parametricRoute)
      this.parametricRoute = null

      // Route behind
      this.removeFromMap(this.routeBehind)
      this.routeBehind = null

      const pd = this.currentPathData
      if (pd.behindSlice) {
        var pointsBehind = this.layer.route.slice(pd.behindSlice[0], pd.behindSlice[1] + 1)

        pointsBehind.push(this.currentPathData.currentPosition)

        this.routeBehind = new L.polyline(pointsBehind, {
          color: this.layer.color,
          weight: 3,
          opacity: 1.0,
          smoothFactor: 1,
          pane: this.mapUpperPaneName
        })

        this.routeBehind.addTo(this.map)
      }

      // Route ahead
      this.removeFromMap(this.routeAhead)
      this.routeAhead = null

      const playingLive = FSPModel.clock.isPlayingLive

      if (pd.aheadSlice && !playingLive) {
        var pointsAhead = this.layer.route.slice(pd.aheadSlice[0], pd.aheadSlice[1])

        this.routeAhead = new L.polyline(pointsAhead, {
          color: this.layer.color,
          weight: 3,
          opacity: 0.5,
          smoothFactor: 1,
          dashArray: '8',
          pane: this.mapLowerPaneName
        })
        this.routeAhead.addTo(this.map)
      }
    } catch (error) {
      // Silencing errors for now
      // console.error(error)
      // throw error
    }
  }

  getParameterRelativeDeviation(parameter) {
    try {
      const layer = FSPModel.getLayerById(this.layerId)
      const value = layer.getSeries(parameter)
      const target = layer.getTargetSeries(parameter)
      if (value && target) {
        return value.map((v, i) => {
          // console.log("" + i + " " + v + " " + target[i] + " -> " + v / target[i])
          return v / target[i]
        })
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  drawParametricPath() {
    this.removeFromMap(this.routeBehind)
    this.routeBehind = null

    this.removeFromMap(this.routeAhead)
    this.routeAhead = null

    this.removeFromMap(this.parametricRoute)
    this.parametricRoute = null

    const pd = this.currentPathData
    const first = pd.behindSlice ? pd.behindSlice[0] : 0
    const last = pd.aheadSlice ? pd.aheadSlice[1] : this.layer.route.length

    const points = this.layer.route.slice(first, last)
    const colors = this.colorParameter.slice(first, last)

    this.parametricRoute = L.polycolor(points, {
      colors: colors,
      weight: 5
    })
    this.parametricRoute.addTo(this.map)
  }

  relocate(position) {
    const pd = this.currentPathData
    if (pd) {
      var time = 0
      var dist = Infinity

      const first = pd.behindSlice ? pd.behindSlice[0] : 0
      const last = pd.aheadSlice ? pd.aheadSlice[1] : this.layer.route.length

      for (var i = first; i < last; i++) {
        const p = this.layer.route[i]

        const dLat = p.lat - position.lat
        const dLng = p.lng - position.lng
        // let d = dLat * dLat + dLng * dLng;
        const d = Math.abs(dLat) + Math.abs(dLng)
        if (d < dist) {
          dist = d
          time = p.time
        }
      }
      FSPModel.clock.playerTime = time
    }
  }

  timeAt(position) {
    const pd = this.currentPathData
    if (pd) {
      var time = 0
      var dist = Infinity

      const first = pd.behindSlice ? pd.behindSlice[0] : 0
      const last = pd.aheadSlice ? pd.aheadSlice[1] : this.layer.route.length

      for (var i = first; i < last; i++) {
        const p = this.layer.route[i]

        const dLat = p.lat - position.lat
        const dLng = p.lng - position.lng
        // let d = dLat * dLat + dLng * dLng;
        const d = Math.abs(dLat) + Math.abs(dLng)
        if (d < dist) {
          dist = d
          time = p.time
        }
      }
      return dist < 0.0001 ? time : NaN
    }
    return NaN
  }

  removeFromMap(element) {
    if (element == null) return
    try {
      this.map.removeLayer(element)
    } catch (error) {
      console.error(error)
    }
  }

  destroy() {
    this.removeFromMap(this.routeBehind)
    this.removeFromMap(this.routeAhead)
    this.removeFromMap(this.parametricRoute)
    this.removeFromMap(this.boatMarker)
  }

  refresh(time) {
    this.#routeUpdateTime = -1
    this.removeFromMap(this.routeBehind)
    this.routeBehind = null

    this.removeFromMap(this.routeAhead)
    this.routeAhead = null

    this.removeFromMap(this.parametricRoute)
    this.parametricRoute = null

    if (this.boatMarker != null && (this.boatMarker.iconURL !== this.layer.boatIcon ||
      this.boatMarker.color !== this.layer.color)) {
      this.removeFromMap(this.boatMarker)
      this.boatMarker = null
    }

    this.draw(time)
  }
}

export { BoatPath }
