<template>
  <v-dialog v-model="dialog" width="720" style="z-index: 999999999">

    <v-card style="background-color: #154b65; max-width: 720px; border-color: black;">
      <div class="dialog_title">SELECT TIME</div>

      <v-container class="white--text">
        <!-- <v-row style="margin-left: 5px; margin-right: 5px;">
          <v-date-picker class="mr-6" v-model="date"></v-date-picker>
          <v-time-picker use-seconds ampm-in-title v-model="datetime"></v-time-picker>
        </v-row> -->

        <div style="width:100%; display: flex; justify-content: space-around; flex-wrap: wrap;">
          <div style="margin-bottom: 5px;">
            <v-date-picker v-model="date"></v-date-picker>
          </div>
          <div>
            <v-time-picker use-seconds ampm-in-title v-model="datetime"></v-time-picker>
          </div>
        </div>

      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="closeDialog()"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>

.v-picker__title {
    color: #FFFFFF;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 16px;
    width: 290px !important;
    height: 102px !important;
}

.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
    align-items: center;
    display: inline-flex;
    height: 70px;
    font-size: 60px;
    justify-content: center;
}

.v-label {
  color: white !important;
}

.v-input input {
  max-height: 32px;
  color: white !important;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot::before {
  border-color: rgba(255, 255, 255, 255) !important;
}
</style>

<script>
import DropFileArea from './DropFileArea.vue'

export default {
  name: 'DataLayerInfoDialog',

  components: { DropFileArea },

  data: () => ({
    dialog: false,
    datetime: new Date(),
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  }),

  mounted () {

  },

  methods: {
    openDialog (date) {
      this.datetime = date
      this.dialog = true;
      this.date = (new Date(this.datetime - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

      this.$nextTick(() =>{
        var parentElement = document.querySelector(".v-time-picker-title__time");
        var children = parentElement.children;
        for (var i = 0; i < children.length; i++) {
            children[i].style.fontSize = "60px";
        }
      })


    },
    closeDialog(){
      this.dialog = false;
      var time = new Date()
      if (!(this.datetime instanceof Date)) {
          time.setHours(Number(this.datetime.slice(0, 2)))
          time.setMinutes(Number(this.datetime.slice(3, 5)))
          time.setSeconds(Number(this.datetime.slice(6, 8)))
      } else {
        time = this.datetime
      }
      var date = new Date(this.date)
      time.setFullYear(date.getFullYear())
      time.setMonth(date.getMonth())
      time.setDate(date.getDate())

      this.$emit("onChange", time);
    },
    getTime(){
      return this.datetime;
    }
  },
}
</script>
