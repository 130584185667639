<template>
  <v-dialog v-model="dialog" width="500" style="z-index: 999999999" :dark="true">
    <v-card style="background-color: #1a5b78">
      <div class="dialog_title">SELECT SERIES</div>

      <FSPDataLayerSeriesPicker :categories="categories" :isMultiple="selectMany" />

      <v-select dark dense
        v-model="selectedLayerId"
         :items="getLayerNames()"
          label="Source"
           outlined width="50px"
            v-on:change="changeLayer" style="margin-left: 15px; margin-right: 15px; margin-bottom: -25px;">
        </v-select>
        <v-divider style="margin-top: 5px"></v-divider>
      <v-card-actions style="justify-content: flex-end;">
        <v-btn color="white" text @click="closeDialog()"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

#title {
  background: rgb(26, 50, 88);
  background: linear-gradient(90deg,
      rgba(26, 50, 88, 1) 0%,
      rgba(4, 33, 43, 1) 100%);
  font-size: 16px;
}

.selectButton {
  font-family: "Open Sans", sans-serif;
  font-size: 8px;
}

.layerLabel {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.option-chip {
  width: 100px;
}
</style>

<script>
import { EventBus, FSPModel } from '../Globals'
import { FSPData } from './FSPLayers/FSPData.js'
import FSPDataLayerSeriesPicker from './FSPDataLayerSeriesPicker.vue'

export const FSPDataLayerDefaultSeries = ['SOG', 'HEEL', 'PITCH', 'COURSE']

export default {

  components: {
    FSPDataLayerSeriesPicker
  },
  name: 'SelectSeriesDialog',

  props: {
    selectMany: {
      type: Boolean,
      default: 100
    },
    model: {
      type: Array,
      default: []
    }
  },

  data: () => ({
    dialog: false,
    selectedLayerId: null,
    tab: null,
    categories: [],
    selected: null
  }),

  mounted () {
    EventBus.$on(EventBus.LAYER_LOADED, () => {
      var ls = FSPModel.getLayersOfType(FSPData)
      if (ls.length === 1) {
        this.showSeriesForFirstLayer()
      }
    })

    EventBus.$on(EventBus.LAYER_CHANGED, () => {
      this.$forceUpdate()
    })

    EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
      if (id === this.selectedLayerId) {
        this.selectedLayerId = null
        this.showSeriesForSelectedLayer()
      }
      // this.categories = this.categories.filter(s => s.selectedLayerId != id);
    })
  },

  methods: {

    showDialog () {
      this.dialog = true
      const hasAllSources = FSPModel.getLayersOfType(FSPData).length > 1
      if (hasAllSources) {
        this.selectedLayerId = -1
      }
      this.showSeriesForSelectedLayer()
    },

    getLayerNames () {
      const ls = FSPModel.getLayersOfType(FSPData)
      const names = ls.map((d) => { return { text: d.name, value: d.id } })
      if (names.length > 1) {
        names.push({ text: 'All Sources', value: -1 })
      }
      return names
    },

    getSelectedLayerName () {
      if (this.selectedLayerId == null) {
        return 'NO LAYER'
      }
      return FSPModel.getFSPDataWithId(this.selectedLayerId).name
    },

    changeLayer (event) {
      this.selectedLayerId = event
      this.showSeriesForSelectedLayer()
    },

    showSeriesForFirstLayer () {
      const ls = FSPModel.getLayersOfType(FSPData)
      if (ls.length > 0) {
        const data = ls[0]
        this.selectedLayerId = data.id
        this.showSeriesForSelectedLayer()
      }
      this.$forceUpdate()
    },

    showSeriesForSelectedLayer () {
      this.categories = []
      const datas = this.selectedLayerId > -1
        ? [FSPModel.getFSPDataWithId(this.selectedLayerId)]
        : FSPModel.getLayersOfType(FSPData)

      function addCategory (list, cat, names, layerId) {
        if (!list.some(c => c.tab === cat)) {
          list.push({ tab: cat, items: [], selected: [], layerId: layerId })
        }

        const c = list.find(c => c.tab === cat)
        if (c) {
          c.items = [...new Set([...c.items, ...names])] // remove repeated
        }
      }

      for (const data of datas) {
        if (data) {
          const cats = data.getSeriesCategories()
          for (const [cat, names] of cats) {
            addCategory(this.categories, cat, names, this.selectedLayerId)
          }
        }
      }
      this.$forceUpdate()
    },

    closeDialog () {
      this.dialog = false

      var names = []
      for (const cat of this.categories) {
        var ns = null
        if (typeof cat.selected === 'number') {
          ns = [{ layerId: cat.layerId, seriesName: cat.items[cat.selected] }]
        } else {
          ns = cat.selected.map((i) => ({ layerId: cat.layerId, seriesName: cat.items[i] }))
        }
        names = names.concat(ns)
      }
      this.$emit('send-message', names)
    }
  }
}
</script>
