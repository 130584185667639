import { Line, mixins } from 'vue-chartjs'
import { approximate } from '../../PolynomialApproximation'
const { reactiveProp } = mixins

const data = {
    labels: [],
    datasets: [{
        type: 'line',
        label: 'Tendency Line',
        data: [],
        fill: false,
        backgroundColor: 'rgba(218,83,79, .7)',
        borderColor: 'rgba(218,83,79, .7)',
        pointRadius: 0
    },
    {
        type: 'bubble',
        label: 'Real',
        data: [],
        backgroundColor: 'rgba(76,78,80, .7)',
        borderColor: 'transparent'
    }]
}

const options = {
    tooltips: {
        callbacks: {
            title: function (tooltipItems, data) {
                let dataset = data.datasets[tooltipItems[0].datasetIndex];
                return dataset.label;
            },
            label: function (tooltipItem, data) {
                let index = tooltipItem.index;
                let dataset = data.datasets[tooltipItem.datasetIndex];
                let p = dataset.data[index];
                return Number(p.x).toFixed(2) + " - " + Number(p.y).toFixed(2);
            }
        },
        backgroundColor: '#FFF',
        titleFontSize: 16,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 24,
        displayColors: false
    },
    scales: {
        xAxes: [{
            id: 'x-axis',
            type: 'linear',
            position: 'bottom',
            min: 0,
            max: 10,
            ticks: {
                autoSkip: true
                // max: Math.max(...makeLabels().arr)
            },
            title: {
                display: true,
                text: 'Your Title'
            },
            scaleLabel: {
                display: true,
                labelString: 'X'
            }
        }],
        yAxes: [{
            id: 'y-axis',
            title: {
                display: true,
                text: 'yTitle'
            },
            scaleLabel: {
                display: true,
                labelString: 'Y'
            }
        }]
    },
    responsive: true,
    maintainAspectRatio: false
}

function groupPoints(points, times, secs) {

    if (secs <= 0) {
        return points;
    }

    var ts = times.map(t => t.getTime());
    let ms = secs * 1000;
    var t = ts[0];
    console.assert(ts.length == points.length);

    var groupedPoints = [];
    var i = 0;
    while (i < points.length) {
        var j = i;
        var mX = 0;
        var mY = 0;
        var nextT = t + ms;
        while (j < points.length && ts[j] < nextT) {
            mX += points[j].x;
            mY += points[j].y;
            j++;
        }

        mX /= (j - i);
        mY /= (j - i);

        groupedPoints.push({ x: mX, y: mY });
        i = j + 1;
        t = nextT;
    }
    return groupedPoints;
}

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: [
        'chartData',
        'options',
        'dataPoints'
    ],
    data() {
        return {
            myChartData: data,
            myChartOptions: options
        }
    },
    mounted() {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.updateDataPoints()
        this.renderChart(this.myChartData, this.myChartOptions, { responsive: true, maintainAspectRatio: false })
    },
    watch: {
        dataPoints: {
            handler() {
                this.updateDataPoints()
                this.renderChart(this.myChartData, this.myChartOptions, { responsive: true, maintainAspectRatio: false })
            },
            deep: true
        }
    },
    methods: {
        getContext2D() {
            return this.getContext('2d')
        },



        updateDataPoints() {
            this.myChartData = data
            var ds = this.myChartData.datasets[1]

            var points = this.dataPoints.points.filter(p => !isNaN(p.x) && !isNaN(p.y))

            var points = [];
            var times = [];
            for(var i = 0; i < this.dataPoints.points.length; i++){
                let p = this.dataPoints.points[i];
                if (!isNaN(p.x) && !isNaN(p.y)){
                    points.push(p);
                    times.push(this.dataPoints.times[i]);
                }
            }

            ds.data = groupPoints(points, times, this.dataPoints.groupSecs)
            ds.label = `${this.dataPoints.xSeriesName} vs. ${this.dataPoints.ySeriesName}`;

            var xs = this.dataPoints.points.map(p => p.x)
            xs = xs.sort((a, b) => a - b)
            this.myChartData.labels = xs
            
            const tendencyLine = approximate(points, 4)
            ds = this.myChartData.datasets[0]
            ds.data = tendencyLine

            this.myChartOptions = options
            let xAxis = this.myChartOptions.scales.xAxes[0];
            let yAxis = this.myChartOptions.scales.yAxes[0];
            xAxis.ticks.min = xs[0];
            xAxis.ticks.max = xs[xs.length - 1];
            xAxis.scaleLabel.labelString = this.dataPoints.xSeriesName;
            yAxis.scaleLabel.labelString = this.dataPoints.ySeriesName;
        }
    }
}
