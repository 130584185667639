<template>
  <v-flex ref="map_container" blue fill-height>

    <l-map ref="leafletMap" :zoom="zoom" :center="center" :options="{ zoomControl: false, maxZoom: 22, zoomDelta: 0.25 }">

      <div v-if="liveVideoDialog" id="liveVideoDialogContainer" v-html="getLiveVideoHTML()"></div>

      <select-layer-widget v-if="!liveVideoDialog" ref="select_layer_widget" @send-message="dialogChangedLayers" class="selectLayerWidget"
        :selectVideoLayers="false" selectFSPDataLayers></select-layer-widget>

      <div class="mapBottomArea">

        <!-- LIVE VIDEO BUTTON AND DIALOG -->
        <v-btn class="mx-2" fab dark small color="#004a82" v-if="hasLiveVideo()"
          style="margin: 10px" @click="liveVideoDialog = !liveVideoDialog" >
          <v-icon :color="liveVideoDialog? 'red' : 'white'">mdi-broadcast</v-icon>
        </v-btn>

        <map-legend v-if="boatTrailType !== 0" style="   z-index: 9999999;
                              position: absolute;
                              bottom: 0px;
                              width: 50%;
                              left: 55px;"></map-legend>

        <v-menu v-model="menu" :close-on-content-click="false" top offset-y>
          <template v-slot:activator="{ attrs }">
            <v-btn v-if="!liveVideoDialog" class="mx-2" fab dark small color="#004a82" v-bind="attrs" style="margin: 10px" @click="menu = true;">
              <v-icon dark>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                Boat Path Length
                <!-- <v-list-item-title>Boat Path Length</v-list-item-title> -->
                <v-slider class="time-slider" v-model="pathLength" max="1000" min="0"></v-slider>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Targets</v-list-item-title>
                <v-btn-toggle v-model="boatTrailType" mandatory>
                  <v-btn v-for="item in boatTrailTypes" :key="item">
                    {{ item }}
                  </v-btn>
                </v-btn-toggle>
              </v-list-item>

              <v-list-item style="padding-top: 10px;">
                <v-select id="followBoatSelector" v-model="followedBoatName" dense :items="followBoatNames"
                  label="Follow boat" outlined width="50px" style="color: black !important">
                </v-select>
              </v-list-item>

              <!-- switch for showing names -->
              <v-list-item>
                <!-- <v-list-item-title>Show Sportsmen Names</v-list-item-title>
                <v-switch v-model="showNames" color="primary"></v-switch> -->

                <v-list-item-title>Show Sportsmen Names and Speeds</v-list-item-title>
                <v-switch v-model="showNamesAndSpeeds" color="primary"></v-switch>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="menu = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <l-tile-layer :url="url" :attribution="attribution" :options="{ maxNativeZoom: 18, maxZoom: 100 }"></l-tile-layer>
        <l-control-zoom v-if="!liveVideoDialog" position="bottomright"></l-control-zoom>
      </div>
    </l-map>
  </v-flex>
</template>

<style>
label.v-label.v-label--active.theme--light {
  color: black !important;
}
</style>

<style scoped>
.mapBottomArea {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 60px;
  background-color: transparent;
  z-index: 999;
  pointer-events: none;
}

.mapBottomArea>* {
  pointer-events: auto;
}

.v-label {
  color: black !important;
}

.leaflet-container {
  background-color: #9bc9d7;
}

label.v-label.v-label--active.theme--light {
  color: black !important;
}

.selectLayerWidget {
  position: relative;
  top: 10px;
  left: 10px;
  z-index: 997
}

.time-slider {
  color: black !important;
  background-color: transparent;
  margin: 3px;
  width: 300px;
  margin-top: 20px;
}

.mapButtonsPanel {
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 99999
}

.trackLengthPanel {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 99999
}

div.leaflet-overlay-pane svg>g path {
  filter: url(#f1);
}

.blur_filter {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(15px);
  filter: blur(15px);
  width: 1000px;
  height: 1000px;
  background-color: #ccc;
}

#liveVideoDialogPanel {
  width: 100%;
  height: 100%;
  position: absolute;
  /* Esto permite que el div hijo ocupe todo el espacio del contenedor */
  top: 0px;
  right: 0px;
  z-index: 998;
  background-color: transparent;
  /* Fondo transparente */
  pointer-events: none;
  /* Permite que los eventos de ratón pasen a través del div hijo */
}

#liveVideoDialogContainer> :first-child {
  width: 100%;
  height: 100%;
}

#liveVideoDialogContainer {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  /* Color de fondo para visualizar el div hijo */
  position: absolute;
  /* Esto permite que el div hijo ocupe todo el espacio del contenedor */
  top: 0px;
  right: 0px;
  z-index: 998;
}

.video {
  z-index: 0;
}
</style>

<script>
import { LMap, LTileLayer, LControlZoom } from 'vue2-leaflet'
import { EventBus, FSPModel } from '../../Globals'
import SelectLayerWidget from '../SelectLayerWidget.vue'

import L from 'leaflet'

import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'

import leafletPolycolor from 'leaflet-polycolor'
import 'leaflet.heat'

import { BoatPath } from './BoatPath'

// Solving icon problem
import { Icon } from 'leaflet'
import { Buoy } from './Buoy'
import { BuoyLayer } from '../FSPLayers/BuoyLayer.js'
import { FSPData } from '../FSPLayers/FSPData'
import MapLegend from './MapLegend.vue'

leafletPolycolor(L)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'LeafletMap',
  components: {
    LMap,
    LTileLayer,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    SelectLayerWidget,
    LControlZoom,
    MapLegend
    // Vue2LeafletHeatmap
  },
  data: () => ({
    // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    attribution: '',
    //   '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 14,
    // center: [59.9375, 30.308611], //Saint Petersburg
    center: [0, 0],
    attributionControl: false, // TODO Check
    boatPaths: [],
    lastMapContainerSize: {},
    buoys: [],
    boatTrailType: 0,
    boatTrailTypes: ['Path', 'VMG', 'TWA', 'BS'],
    pathLength: 1000,
    menu: false,
    followedBoatLayerId: null,
    followedBoatName: 'None',
    liveVideoDialog: false,
    showNames: false,
    followBoatNames: ['None'],
    showNamesAndSpeeds: false
  }),
  methods: {
    dialogChangedLayers (layers) {
      debugger
      const boatIds = this.boatPaths.map((b) => b.layer.id)

      const boatsToRemove = boatIds.filter((boat) => !layers.includes(boat))
      const layersToAdd = layers.filter((id) => !boatIds.includes(id))

      for (const id of boatsToRemove) {
        this.removeBoatPath(id)
      }

      for (const id of layersToAdd) {
        this.addBoatFromLayer(FSPModel.getFSPDataWithId(id))
      }

      if (this.boatPaths.length > 0) {
        this.boatPaths[0].centerMapOnPathStart()
      }

      this.draw(FSPModel.clock.playerTime)
    },

    removeBoatPath (layerId) {
      debugger
      for (const path of this.boatPaths) {
        if (path.layer.id === layerId) {
          path.destroy()
        }
      }
      this.boatPaths = this.boatPaths.filter((p) => p.layer.id !== layerId)
      // this.updateLayerSelectDialog()
    },

    removeBuoysForLayerId (layerId) {
      for (const b of this.buoys) {
        if (b.layerId === layerId) {
          b.clear()
        }
      }
      this.buoys = this.buoys.filter((p) => p.layerId !== layerId)
    },

    draw (t) {
      const map = this.$refs.leafletMap.mapObject
      if (map) {
        for (const path of this.boatPaths) {
          path.draw(t)
          if (path.layer.id === this.followedBoatLayerId) {
            const loc = path.getLocation()
            map.panTo(loc)
            console.log('Panning to ', loc, this.followedBoatLayerId)
          }
        }
        for (const b of this.buoys) {
          b.draw(t)
        }
      }
    },

    addBoatFromLayer (data, centerOnStart = false) {
      try {
        if (!this.$refs.leafletMap) {
          console.warn('addBoatFromLayer() failed.')
          return
        }

        const map = this.$refs.leafletMap.mapObject
        const path = new BoatPath(data, this.showNames, map)
        path.showLabel = this.showNamesAndSpeeds
        path.setPathLength(this.getPathLength())
        path.setTrailType(this.boatTrailTypes[this.boatTrailType])
        this.boatPaths.push(path)
        if (centerOnStart) {
          path.centerMapOnPathStart()
        }
      } catch {
        console.error('addBoatFromLayer() failed.')
      }
    },

    getPathLength () {
      const maxValue = 1000
      if (this.pathLength >= maxValue) return NaN

      var x = this.pathLength / maxValue
      x = x === 0 ? 0 : Math.pow(2, 10 * x - 10)
      return x * maxValue // No path length at max
    },

    addBuoysFromLayer (layer) {
      if (!this.$refs.leafletMap) {
        console.warn('addBuoysFromLayer() failed.')
        return
      }

      var map = this.$refs.leafletMap.mapObject
      layer.buoys.forEach((positions, name) => {
        var buoy = new Buoy(layer.id, name, positions, map)
        this.buoys.push(buoy)
      })
    },

    showMenu () {
      this.menu = true
    },

    computeBoatNames () {
      this.followBoatNames = ['None'].concat(this.boatPaths.map(d => d.layer.name))
    },

    isPlayingLive () {
      return FSPModel.clock.isPlayingLive
    },

    getLiveVideoHTML () {
      return FSPModel.liveVideoHTML
    },

    hasLiveVideo () {
      return FSPModel.liveVideoHTML != null
    }
  },
  mounted () {
    setInterval(() => {
      const mapContainer = this.$refs.map_container
      if (mapContainer) {
        const size = { x: mapContainer.offsetHeight, y: mapContainer.offsetWidth }
        if (size.x !== this.lastMapContainerSize.x || size.y !== this.lastMapContainerSize.y) {
          const map = this.$refs.leafletMap.mapObject
          map.invalidateSize()
          this.lastMapContainerSize = size
        }
      }
    }, 200)

    // Replacing lod controls
    document.getElementsByClassName(
      'leaflet-control-attribution'
    )[0].style.display = 'none'

    // Adding custom controls
    /*
    var map = this.$refs.leafletMap.mapObject
    L.Control.Watermark = L.Control.extend({
        onAdd: function(map) {
            // var img = L.DomUtil.create('img');
            // img.src = 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_light_color_92x30dp.png';
            // img.style.width = '200px';
            return '<v-slider class="time-slider" label="Boat Path Length" v-model="pathLength" max="1000" min="200"></v-slider>'
        },

        onRemove: function(map) {
            // Nothing to do here
        }
    });

    L.control.watermark = function(opts) {
        return new L.Control.Watermark(opts);
    }

    L.control.watermark({ position: 'bottomleft' }).addTo(map);
    */

    EventBus.$on(EventBus.LAYER_LOADED, (layer) => {
      if (layer instanceof FSPData) {
        this.addBoatFromLayer(layer, true)
        if (this.$refs.select_layer_widget) {
          this.$refs.select_layer_widget.select(layer.id)
        }
      }

      if (layer instanceof BuoyLayer) {
        this.addBuoysFromLayer(layer)
      }

      this.computeBoatNames()
      this.draw(FSPModel.clock.playerTime)
    })

    EventBus.$on(EventBus.TIME_CHANGED, (t) => {
      debugger
      this.draw(t)
    })

    EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
      this.removeBuoysForLayerId(id)
      this.removeBoatPath(id)
      this.computeBoatNames()
      this.draw(FSPModel.clock.playerTime)
      console.log(`Showing ${this.boatPaths.length} boats.`)
    })

    EventBus.$on(EventBus.LAYER_CHANGED, (id) => {
      this.computeBoatNames()

      const layer = FSPModel.getFSPDataWithId(id)
      if (layer instanceof FSPData) {
        for (const boatPath of this.boatPaths) {
          if (boatPath.layer.id === id) {
            boatPath.refresh(FSPModel.clock.playerTime)
          }
        }
      }
    })

    var map = this.$refs.leafletMap.mapObject
    map.boatTrailType = this.boatTrailTypes[this.boatTrailType]

    const paths = this.boatPaths
    map.on('click', function (e) {
      for (const b of paths) {
        const t = b.timeAt(e.latlng)
        if (!isNaN(t)) {
          FSPModel.clock.playerTime = t
        }
      }
    })
  },

  watch: {
    boatTrailType () {
      for (var b of this.boatPaths) {
        b.setTrailType(this.boatTrailTypes[this.boatTrailType])
      }
      this.draw(FSPModel.clock.playerTime)
    },
    pathLength () {
      var pl = this.getPathLength()
      for (var b of this.boatPaths) {
        b.setPathLength(pl)
      }
    },

    followedBoatName () {
      const x = this.boatPaths.find(b => b.layer.name === this.followedBoatName)
      this.followedBoatLayerId = x ? x.layer.id : null
    },

    showNames () {
      for (const b of this.boatPaths) {
        b.createTooltip(this.showNames)
      }
    },

    showNamesAndSpeeds () {
      debugger
      for (const b of this.boatPaths) {
        b.switchLabel(this.showNamesAndSpeeds)
      }
    }
  }
}
</script>
