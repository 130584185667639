import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify/lib'

import "./css/main.css"

import splitPane from 'vue-splitpane'

// Leaflet
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

// Multipane
import { Multipane, MultipaneResizer } from 'vue-multipane'

// CSV Parser
Vue.component('split-pane', splitPane)
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('multipane', Multipane)
Vue.component('multipane-resizer', MultipaneResizer)

// import DatetimePicker from 'vuetify-datetime-picker'
// //import 'vuetify-datetime-picker/src/stylus/main.styl'
//  Vue.use(DatetimePicker)

// import Calendar from 'v-calendar/lib/components/calendar.umd'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// // Register components in your 'main.js'
// Vue.component('calendar', Calendar)
// Vue.component('date-picker', DatePicker)

// Moment.js
// Vue.use(require('vue-moment'));

const meta = document.createElement('meta');
meta.httpEquiv = "viewport";
meta.content = "width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover";
document.getElementsByTagName('head')[0].appendChild(meta);

//Background color
const html = document.getElementsByTagName("html")
html[0].style.backgroundColor = "#323366"


document.addEventListener('gesturestart', function (e) {
  e.preventDefault();
});

Vue.config.productionTip = false

new Vue({
  vuetify_: vuetify,
  render: h => h(App),

  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#1b2e4e' // Background
        }
      }
    }
  })

}).$mount('#app')
