<template>
  <v-overlay :absolute="absolute" opacity="1.0" z-index="9999999" class="">

    <!-- <v-overlay v-model="loadingOverlay" :absolute="absolute" opacity="1.0" z-index="99999999" class="">
      LOADING
      <v-progress-linear indeterminate color="white"></v-progress-linear>
    </v-overlay> -->

    <div class="full-screen-container">

      <div v-if="showFileArea" class="header-image">
        <v-img src="@/assets/fast skipper-white.png" alt="Cabecera" class="header-img"></v-img>
      </div>

      <div v-if="loadingOverlay" >
        LOADING
      <v-progress-linear indeterminate color="white"></v-progress-linear>
      </div>
      <v-container v-else class="content-container" style="height: 100vh;">
        <!-- El resto del contenido -->
        <v-row class="align-center">
          <v-col v-if="showFileArea" cols="12" sm="12" :md="showFileArea ? 4 : 6" class="column-spacing">
            <div style="height:100px">
              <drop-file-area @onFiles="onFiles" text="PLAYER" />
            </div>
          </v-col>
          <v-col cols="12" sm="12" :md="showFileArea ? 4 : 6">
            <FSPEventGrid gridTitle="Live Events" :items="liveEvents" :N=3 :onClickCallback="onLiveEventClicked">
            </FSPEventGrid>
          </v-col>
          <v-col cols="12" sm="12" :md="showFileArea ? 4 : 6">
            <FSPEventGrid gridTitle="Latest Events" :items="pastEvents" :N=3 :onClickCallback="onPastEventClicked">
            </FSPEventGrid>
          </v-col>
        </v-row>
        <v-row>
          <!-- SPINNER -->

        </v-row>
      </v-container>

      <v-snackbar v-model="snackbar" color="red" :top="false" :bottom="true" :right="false" :absolute="true"
        :multi-line="false" :vertical="true">
        Unable to load events. Please try again later.
      </v-snackbar>

    </div>
  </v-overlay>
</template>

<script>
import DropFileArea from '@/components/DropFileArea.vue'
import FSPEventGrid from './FSPEventGrid.vue'
import { loadFSPEvent, updateFSPData } from '././FSPLayers/FSPDataLiveUpdate.js'
import { FSPModel } from '../Globals'

export default {
  name: 'LandingMenu',
  components: {
    DropFileArea,
    FSPEventGrid
  },
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    showFileArea: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      snackbar: false,
      loadingOverlay: false,
      liveEvents: [
        { title: 'Item 1', date: '2023-04-20', icon: require('../assets/Coach.png') }
      ],
      pastEvents: [
        { title: 'Item 1', date: '2023-04-20', icon: require('../assets/Coach.png') }
      ]
    }
  },
  methods: {
    onDropFileAreaError () {
      this.$emit('onDropFileAreaError')
    },

    onFiles (files) {
      FSPModel.onNewFiles(files, (error) => {
        if (error) {
          this.snackbar = true
          setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      })
    },

    onEventDataLoaded (events) {
      const icon = require('../assets/Coach.png')
      this.liveEvents = events.live_events.map(event => {
        return {
          title: event.name,
          date: event.time,
          icon: icon,
          id: event.id
        }
      })

      this.pastEvents = events.past_events.map(event => {
        return {
          title: event.name,
          date: event.time,
          icon: icon,
          id: event.id
        }
      })
    },

    loadEvent (eventName, eventURL, isLive) {
      const onError = (error) => {
        console.error('Error:', error)
        this.snackbar = true
        this.loadingOverlay = false
      }

      this.loadingOverlay = true
      fetch(eventURL, {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(response => response.json())
        .then(eventData => {
          loadFSPEvent(eventName, eventData, () => {
            this.$emit('onEventLoaded', eventData, isLive)
            this.loadingOverlay = false
          }, onError
          )
        })
        .catch(onError)
    },

    onLiveEventClicked (event) {
      const eventURL = 'http://server.fastskipper.com:3001/api/v1/events/get-event?id=' + event.id
      this.loadEvent(event.title, eventURL, true)
    },

    onPastEventClicked (event) {
      const eventURL = 'http://server.fastskipper.com:3001/api/v1/events/pasts/' + event.id
      this.loadEvent(event.title, eventURL, false)
    }

  },
  mounted () {
    console.log('LandingMenu mounted')

    fetch('http://server.fastskipper.com:3001/api/v1/events', {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => this.onEventDataLoaded(data))
      .catch((error) => {
        console.error('Error:', error)
        this.snackbar = true
      })
  }

}
</script>

<style scoped>
.header-image {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000000;
}

.header-img {
  max-width: 250px;
  width: 80%;
}

.full-screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

.content-container {
  padding-top: 200px;
  /* Ajusta el valor de 10% según el tamaño de la imagen */
}

.column-spacing {
  padding-right: 25px;
  padding-left: 25px;
}

.column-spacing:last-child {
  padding-right: 0;
}

.v-overlay> :first-child {
  background: linear-gradient(180deg, #222257, #272727) !important;
  color: white;
  padding: 20px;
  font-size: 24px;
}
</style>
