import { FSPModel } from '../../Globals'
import { BuoyLayer } from './BuoyLayer'
import { FSPData, loadCategoriesMapFromFile } from './FSPData'

import { createVideoLayerPromise } from './VideoLayer'
import { createPhotoLayerPromise } from './PhotoLayer'

function getFileNameWithoutExtension (filename) {
  return filename.replace(/\.[^/.]+$/, '')
}

function isVideo (path) {
  const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv']
  const n = path.toLowerCase()
  for (const x of videoExtensions) {
    if (n.includes(x)) {
      return true
    }
  }
  return false
}

function isFileImage (file) {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png']
  return file && acceptedImageTypes.includes(file.type)
}

export function loadFSPDataFromString (filename, dataString, onFinished) {
  const Papa = require('papaparse')
  Papa.parse(dataString, {
    // encoding:"UTF-8",
    complete: (csv) => {
      try {
        const datasetName = getFileNameWithoutExtension(filename)
        if (filename.includes('buoy')) {
          const data = new BuoyLayer(datasetName, csv.data)
          FSPModel.addLayer(data)
        } else {
          const categoriesPromise = loadCategoriesMapFromFile()
          categoriesPromise.then(cat => {
            const data = new FSPData(datasetName, 0, csv.data)
            FSPModel.addLayer(data)
          })
        }
      } catch (excepcion) {
        // throw "Problem parsing " + file.name + ". " + excepcion;
        onFinished('Problem parsing ' + filename + '. ' + excepcion)
        console.error(excepcion)
        return
      }

      onFinished()
    },
    beforeFirstChunk: (chunk) => {
      if (chunk.split('\n')[0].includes('}')) {
        chunk = chunk.substring(chunk.indexOf('\n') + 1)
      }
      return chunk
    },
    error: (_err, _file, _inputElem, reason) => {
      alert(reason)
      onFinished()
    }
  })
}

export function loadFSPLayer (files, onFinished) {
  const Papa = require('papaparse')
  var nFiles = files.length
  var onFilesProcessed = (n) => {
    nFiles -= n
    if (nFiles === 0) {
      onFinished()
    }
  }

  // Photos
  files = [...files]
  var images = files.filter(f => isFileImage(f))
  if (images.length > 0) {
    createPhotoLayerPromise('Photo Layer', images)
      .then(layer => {
        if (!isNaN(layer.minDate) && !isNaN(layer.maxDate)) {
          FSPModel.addLayer(layer)
        }
        onFilesProcessed(images.length)
      })
  }
  files = files.filter(f => !isFileImage(f))

  for (const file of files) {
    if (isVideo(file.name)) {
      const datasetName = getFileNameWithoutExtension(file.name)
      createVideoLayerPromise(datasetName, file)
        .then(layer => {
          if (!isNaN(layer.minDate) && !isNaN(layer.maxDate)) {
            FSPModel.addLayer(layer)
          }
          onFilesProcessed(1)
        })
    }
    if (file.name.includes('.csv')) {
      Papa.parse(file, {
        // encoding:"UTF-8",
        complete: (csv) => {
          try {
            const datasetName = getFileNameWithoutExtension(file.name)
            if (file.name.includes('buoy')) {
              const data = new BuoyLayer(datasetName, csv.data)
              FSPModel.addLayer(data)
            } else {
              const categoriesPromise = loadCategoriesMapFromFile()
              categoriesPromise.then(cat => {
                const data = new FSPData(datasetName, 0, csv.data)
                FSPModel.addLayer(data)
              })
            }
          } catch (excepcion) {
            // throw "Problem parsing " + file.name + ". " + excepcion;
            onFinished('Problem parsing ' + file.name + '. ' + excepcion)
            console.error(excepcion)
            return
          }

          onFilesProcessed(1)
        },
        beforeFirstChunk: (chunk) => {
          if (chunk.split('\n')[0].includes('}')) {
            chunk = chunk.substring(chunk.indexOf('\n') + 1)
          }
          return chunk
        },
        error: (_err, _file, _inputElem, reason) => {
          alert(reason)
          onFilesProcessed(1)
        }
      })
    }
  }
}
