<template>
  <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true"
    @dragleave="dragging = false">
    <div class="dropZone-info" @drag="onChange">
      <!-- <span class="fa fa-cloud-upload dropZone-title"></span> -->
      <div class="icon-text-container">
    <div class="pa-4 secondary text-no-wrap rounded-circle plus-chip">
      <v-icon x-large color="white">mdi-plus-thick</v-icon>
    </div>
    <!-- Add a new div to display the text in uppercase and white to the right of the central icon -->
    <div class="dropZone-text" v-if="text">{{ text.toUpperCase() }}</div>
  </div>

    </div>
    <input type="file" onclick="this.value=null;" @change="onChange" :accept="acceptedFiles" multiple />

    <v-alert v-if="errorMessage != null" prominent type="error">
      <v-row align="center">
        <v-col class="grow">
          Error 2: {{ errorMessage }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="errorMessage = null;">Accept</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<style scoped>

.icon-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.dropZone-text {
    color: white;
    font-size: 25px;
    font-weight: bold;
    margin-left: 15px;
  }

.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;  /*Without this the box extends the width of the page*/
}
.plus-chip {
  width: 50px;
  height: 50px;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  align-self: center;
  background: rgb(80, 166, 221);
  background: linear-gradient(90deg,
      rgba(80, 166, 221, 1) 0%,
      rgba(0, 60, 85, 1) 100%);
}

.dropZone {
  width: 100%;
  /* min-height: 90px; */
  height: 100%;
  position: relative;
  border: 4px dashed #eee;
  background: rgb(26, 50, 88);
  background: linear-gradient(90deg,
      rgba(26, 50, 88, 1) 0%,
      rgba(4, 33, 43, 1) 100%);
  border-radius: 30px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: white;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>

<script>
import { FSPModel } from '../Globals'

export default {
  name: 'DropFileArea',

  props: {
    acceptedFiles: {
      type: String,
      default: '.csv, video/*, .mkv, image/png, image/gif, image/jpeg'
    },
    text: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    file: '',
    dragging: false,
    errorMessage: null
    // acceptedFiles: ".csv"
  }),

  methods: {
    getFileNameWithoutExtension (filename) {
      return filename.replace(/\.[^/.]+$/, '')
    },

    onChange (e) {
      FSPModel.clock.stop()

      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        this.dragging = false
        return
      }
      this.$emit('onFiles', files)
      // FSPModel.onNewFiles(files, (error) => {
      //   this.$emit('onEndLoading')
      //   if (error) {
      //     this.$emit('onError', error)
      //   }
      // })
    },
    createFile (file) {
      if (!file.type.match('text.*')) {
        alert('please select txt file')
        this.dragging = false
        return
      }

      if (file.size > 5000000) {
        alert('please check file size no over 5 MB.')
        this.dragging = false
        return
      }

      this.file = file
      this.dragging = false
    },
    removeFile () {
      this.file = ''
    }
  },
  computed: {
    extension () {
      return this.file ? this.file.name.split('.').pop() : ''
    }
  }
}
</script>
