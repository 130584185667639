<template>
  <v-card class="map-legend-card">
    <div class="map-legend">
      <div class="gradient-bar">

        <div :style="gradientStyle">
          <div class="color-labels">
            <span v-for="(color, index) in colors" :key="index">{{ (100.0 * color.value).toFixed(0) + "%" }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'MapLegend',
  data () {
    return {
      colors: [
        { value: '0.80', color: '#808080' },
        { value: '0.85', color: '#d80819' },
        { value: '0.90', color: '#ffa500' },
        { value: '0.95', color: '#f2dc0b' },
        { value: '1.00', color: '#55a81f' },
        { value: '1.05', color: '#2db8b8' },
        { value: '1.10', color: '#1f368c' }
      ]
    }
  },
  computed: {
    gradientStyle () {
      // Normalize color values from 0 to 100
      const values = this.colors.map(color => color.value)
      const min = Math.min(...values)
      const max = Math.max(...values)
      const gradientColors = this.colors.map(color => `${color.color} ${(color.value - min) / (max - min) * 100}%`).join(', ')
      return {
        backgroundImage: `linear-gradient(to right, ${gradientColors})`,
        height: '20px'
      }
    }
  }
}
</script>

<style scoped>
.map-legend-card {
  background-color: white;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}

.map-legend {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gradient-bar {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 20px;
}

.color-labels {
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 10px);
  left: 5px;
  font-weight: bold;
}

.color-labels span {
  font-size: 12px;
  color: black;
}
</style>
