import { FSPModel } from '../../Globals'
import { BuoyLayer } from './BuoyLayer'
import { FSPData, loadCategoriesMapFromFile } from './FSPData'

const Papa = require('papaparse')

const csvBuffer = new Map()
const UPDATE_SECONDS = 2
let eventUpdateInterval = null
let socket = null

export function isLiveEventLoaded () {
  return socket !== null
}

function onNewEventUpdate (update) {
  if (!update) {
    return
  }

  if (update.end_time !== undefined) {
    clearInterval(eventUpdateInterval)
    eventUpdateInterval = null
    socket.close()
    console.log('Event finished')
    return
  }

  // storing data in buffer
  for (const sp of update.sportsmen) { // TODO: NAME IS WRONG
    // storing in map
    csvBuffer.set(sp.id, csvBuffer.has(sp.id) ? csvBuffer.get(sp.id) + '\n' + sp.series : sp.series)
  }

  if (eventUpdateInterval === null) {
    // appending data to sportmen series periodically
    eventUpdateInterval = setInterval(() => {
      const layers = FSPModel.getLayersOfType(FSPData)
      csvBuffer.forEach((series, spId) => {
        const fspDataLayer = layers.find(x => x.sportmanId === spId)
        if (!fspDataLayer) {
          console.error('No layer found for sportsman ' + spId)
          return
        }
        fspDataLayer.appendCSV(series)
      })
      csvBuffer.clear()
    }, UPDATE_SECONDS * 1000)
  }
}

function convertMarksInfoToMatrix (marks) {
  const rows = []
  const header = ['ISODateTimeUTC', 'BOUYNAME', 'LAT', 'LON']
  const currentDate = new Date().toISOString()

  rows.push(header)

  marks.forEach(mark => {
    const row = [
      currentDate,
      mark.name,
      parseFloat(mark.lat).toFixed(5),
      parseFloat(mark.lng).toFixed(5)
    ]
    rows.push(row)
  })

  return rows
}

function processEventData (event) {
  const sportsmen = event.sportsmen ?? event.sportsman // support for old format

  for (const sp of sportsmen) {
    try {
      const x = sp.series[0].split('\n')
      const start = x[1].split(',')[0]
      const end = x[x.length - 2].split(',')[0]
      console.log('Read csv for sportsman ' + sp.name + ' from ' + start + ' to ' + end)
    } catch (e) {
      console.error('Error reading csv for sportsman ' + sp.name)
    }
  }

  if (event.marks && event.marks.length > 0) {
    console.log('Event has marks')
    const marksCSV = convertMarksInfoToMatrix(event.marks)
    const buoysLayer = new BuoyLayer('Marks', marksCSV)
    FSPModel.addLayer(buoysLayer)
  }

  // creating layers for each sportsman
  for (const sp of sportsmen) {
    try {
      console.log("Adding sportsman's series " + sp.name)
      const csvData = sp.series[0] // check if this is the right series
      var parsedCSVData = Papa.parse(csvData, { header: false })
      const data = new FSPData(sp.name, sp.id, parsedCSVData.data)
      FSPModel.addLayer(data)
    } catch (e) {
      console.error('Error adding sportsman ' + sp.name)
    }
  }

  const eventInfo = event.event
  if (eventInfo.embedded_html) {
    FSPModel.liveVideoHTML = eventInfo.embedded_html
    console.log('Event has live video')
  }

  // TEST VIDEO
  // FSPModel.liveVideoHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=WnIMiINNUrYDk-FC&amp;controls=0&amp;autoplay=1&amp;modestbranding=1&amp;rel=0&amp;showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'

  // creating websocket connection
  if (eventInfo.host) {
    console.log('Event has websocket host')
    socket = new WebSocket(eventInfo.host)

    socket.addEventListener('open', (event) => {
      console.log('WebSocket connection established')
    })

    socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed')
    })

    socket.addEventListener('error', (event) => {
      console.error('WebSocket error')
    })

    socket.addEventListener('message', (event) => {
      console.log(event.data)
      console.log(`Received update ${event.data.length / 1024} Kb`)
      const update = JSON.parse(event.data)
      onNewEventUpdate(update)
    })
  }
}

export function loadFSPEvent (eventName, event, onDone, onError) {
  try {
    console.log(event)
    // restart everything
    FSPModel.removeAllLayers()
    FSPModel.liveVideoHTML = null
    FSPModel.eventName = eventName
    csvBuffer.clear()
    if (eventUpdateInterval) {
      clearInterval(eventUpdateInterval)
      eventUpdateInterval = null
    }
    if (socket) {
      socket.close()
      socket = null
    }

    const categoriesPromise = loadCategoriesMapFromFile()
    categoriesPromise.then(cat => {
      try {
        processEventData(event)
        onDone()
      } catch (error) {
        onError(error)
      }
    })
  } catch (e) {
    onError(e)
  }
}
