import { TimeFrameFSPLayer } from './FSPLayer'
import { stringToDate } from '../TimeUtils'

import EXIF from 'exif-js'

export class PhotoLayer extends TimeFrameFSPLayer {
  constructor (name, files) {
    super(name)
    this.imageFiles = files
  }

  prepare (callback) {
    var remainingFiles = this.imageFiles.length
    for (var img of this.imageFiles) {
      EXIF.getData(img, () => {
        remainingFiles--
        if (remainingFiles === 0) {
          for (var img of this.imageFiles) {
            for (var str of [img.exifdata.DateTime,
              img.exifdata.DateTimeDigitized,
              img.exifdata.DateTimeOriginal]) {
              if (str) {
                img.time = stringToDate(str)
                if (!isNaN(img.time)) {
                  break
                }
              }
            }
            if (isNaN(img.time)) {
              console.error('Photo with no date')
            }
          }
          callback()
        }
      })
    }
  }

  getPhotoURLForTime (time) {
    var file = null
    var minDt = Infinity
    for (var img of this.imageFiles) {
      var dt = Math.abs(img.time.getTime() - time.getTime())
      if (dt < minDt) {
        file = img
        minDt = dt
      }
    }

    if (file) {
      if (!file.objectURL) {
        file.objectURL = (window.URL) ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      }
      return file.objectURL
    }
    return null
  }

  get minDate () {
    const times = this.imageFiles.map(p => p.time.getTime())
    if (times.length === 0) {
      return new Date(NaN)
    }
    return new Date(Math.min(...times))
  }

  get maxDate () {
    const times = this.imageFiles.map(p => p.time.getTime())
    if (times.length === 0) {
      return new Date(NaN)
    }
    return new Date(Math.max(...times))
  }

  set minDate (minDate) {
    var dt = this.minDate.getTime() - minDate.getTime()
    var times = []
    for (var img of this.imageFiles) {
      img.time = new Date(img.time.getTime() + dt)
      times.push(img.time.getTime())
    }

    console.log(`New photo dates ${this.minDate} - ${this.maxDate}`)
    this.notifyChange()
  }
}

export function createPhotoLayerPromise (name, files) {
  return new Promise((resolve) => {
    var layer = new PhotoLayer(name, files)
    layer.prepare(() => {
      resolve(layer)
    })
  })
}
