<template>
  <v-dialog v-model="info_dialog" width="720" style="z-index: 999999999" :dark="true">
    <!-- <v-card class="text-white" style="background-color: #112b41">
      <center>
        <v-chip class="ma-2" color="primary"> LAYER SETTINGS </v-chip>
      </center> -->

    <v-card style="background-color: #154b65;">
      <div class="dialog_title">VIDEO LAYER SETTINGS</div>

      <v-container class="white--text">
        <v-row class="ma-5">
          <v-text-field ref="nf" clearable dark label="Layer Name" hide-details="auto" v-model="name"
          :error-messages="nameValid? [] : name.length == 0? 'Empty name' : 'This name belongs to another layer.'"
          ></v-text-field>

        </v-row>
        <v-row class="ma-5">
          <v-text-field ref="nf" readonly dark label="Start Time" hide-details="auto" v-model="datetime"
            style="width: 400px;" />
          <time-dialog ref="time_dialog"></time-dialog>
          <v-btn class="mx-2" small fab dark color="#004a82" @click="openTimeDialog()" style="margin-top: 10px;">
            <v-icon dark> mdi-clock-time-four </v-icon>
          </v-btn>

        </v-row>
        <!-- <v-row class="ma-5">
          <p style="width: 100%; font-size: 13px;">Time</p>
          <v-date-picker class="mr-6" v-model="date"></v-date-picker>
          <v-time-picker use-seconds ampm-in-title v-model="datetime"></v-time-picker>
        </v-row> -->
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="saveChanges(); info_dialog = false;"
        :disabled="!nameValid"> Ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.v-label {
  color: white !important;
}

.v-input input {
  max-height: 32px;
  color: white !important;
}

.theme--light.v-text-field>.v-input__control>.v-input__slot::before {
  border-color: rgba(255, 255, 255, 255) !important;
}
</style>

<script>
import { FSPModel, EventBus } from '../Globals'
import DropFileArea from './DropFileArea.vue'
import { VideoLayer } from './FSPLayers/VideoLayer'
import TimeDialog from './TimeDialog.vue'

export default {
  name: 'VideoLayerInfoDialog',

  components: { DropFileArea, TimeDialog },

  data: () => ({
    layers: [],
    info_dialog: false,
    datetime: new Date(),
    name: 'ok',
    nameValid: false,
    layerId: null
  }),

  mounted () {
  },

  methods: {
    openDialog (datasetId) {
      const fspData = FSPModel.getFSPDataWithId(datasetId)
      this.layerId = fspData.id
      if (fspData != null) {
        this.info_dialog = true
        this.name = fspData.name
        this.datetime = fspData.minDate
      }
    },

    saveChanges () {
      const layer = FSPModel.getLayerById(this.layerId)
      if (layer instanceof VideoLayer) {
        layer.name = this.name
        layer.minDate = this.datetime
      }

      // TODO OVERLAY PROBLEM PATCH
      const overlay = document.getElementsByClassName('v-overlay')
      overlay[0].remove()
    },

    createObjectURL (object) {
      return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object)
    },

    onNewIconURL (files, onDone) {
      this.icon = this.createObjectURL(files[0])
      onDone()
    },

    openTimeDialog () {
      this.$refs.time_dialog.openDialog(this.datetime)

      this.$refs.time_dialog.$on('onChange', (date) => {
        this.datetime = date
      })
    }
  },

  watch: {
    name (newValue, oldValue) {
      this.nameValid = newValue.length > 0
      for (const s of FSPModel.layers) {
        if (s.name === newValue && s.id !== this.layerId) {
          this.nameValid = false
        }
      }
    }
  },

  props: {
    title: String,
    value: String
  }
}
</script>
