<template>
  <div class="wrapper">
    <div class="buttonContainer">
      <select-button style="position: relative; top: 5px; left: 5px; z-index: 99999"
        :selection.sync="dialogSelection"
        @new-selection="onDialogNewSelection"></select-button>
    </div>
    <div class="chart-container" id="cc">
      <scatter-chart v-if="scatterDataPoints.points.length > 0" class="chart" :dataPoints="scatterDataPoints">
      </scatter-chart>
      <time-series-line-chart v-else class="chart" :series="series" :timeRange="timeRange" :adjustYAxis="adjustYAxis">
      </time-series-line-chart>
    </div>

    <div v-if="scatterDataPoints.points.length == 0" class="optionsPanel">
      <v-menu v-model="menu" :close-on-content-click="false" bottom offset-y offset-x nudge-left="200" nudge-top="-10">
        <template v-slot:activator="{ attrs }">
          <v-btn class="mx-2" fab dark small color="#004a82" v-bind="attrs" style="margin: 10px" @click="menu = true;">
            <v-icon dark>
              mdi-cog
            </v-icon>
          </v-btn>
        </template>

        <v-card>

          <v-list>
            <v-list-item>
              <v-btn class="mr-2" fab dark x-small color="primary" @click="timespan = Math.min(timespan * 1.2, 60000)">
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
              ZOOM
              <v-btn class="ml-2" fab dark x-small color="primary" @click="timespan = Math.max(timespan * 0.8, 3000)">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-list-item>

            <!-- <v-list-item>
              <v-checkbox v-model="adjustYAxis" color="indigo" value="indigo" label="Fit Data" hide-details
                class="black--text" style="margin-top: -15px;"></v-checkbox>
            </v-list-item> -->

            <v-list-item>
              <v-btn @click="clearPlot()">CLEAR GRAPH</v-btn>
            </v-list-item>

          </v-list>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="menu = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<style scoped>
.optionsPanel {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99999
}

.axis_control_checkbox {
  position: absolute;
  right: 10px;
  top: 20px;
  padding: 0px;
  padding-top: -5px;
  color: #004564;
}

.black--text /deep/ label {
  color: black !important;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.buttonContainer {
  flex: 0 0 30px;
  width: 100%;
  background-color: transparent;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 10vh;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0px;
}

.chart {
  /* position: relative;
  top: 0px;
  left: 0px; */
  height: 100%;
  width: 100%;
  margin: 0px;
  max-height: 100% !important;
  min-height: 0px;
}

#line-chart {
  background-color: transparent;
  /* max-height: 100% !important; */
}
</style>

<script>
// IMPORTANT to use chart.js@2.9.4 https://stackoverflow.com/questions/67430001/vue-chartjs-unable-to-render-line-chart
// https://codesandbox.io/s/distracted-galileo-r3lec?file=/src/App.vue:32-53

import { EventBus, FSPModel } from '../../Globals'
import SelectButton from '../SelectButton.vue'
import { FSPData, TIME_SERIES_NAME } from '../FSPLayers/FSPData.js'

import ScatterChart from './ScatterChart.js'
import TimeSeriesLineChart from './TimeSeriesLineChart'
import { PlotTimeSeries } from './PlotTimeSeries.js'

const emptyScatterDataPoints = {
  layerId: NaN,
  label: '',
  xSeriesName: '',
  ySeriesName: '',
  points: [],
  times: null,
  groupSecs: 0
}

export default {
  name: 'MultiLinePlot',
  components: {
    SelectButton,
    ScatterChart,
    TimeSeriesLineChart
  },

  methods: {

    onDialogNewSelection (selection) {
      if (selection.timePlot) {
        this.scatterDataPoints = emptyScatterDataPoints
        this.$nextTick(() => {
          const oneLayerSeries = selection.timePlot.filter(s => s[0] > -1)
          const allLayerSeries = selection.timePlot.filter(s => s[0] === -1)
          const layers = FSPModel.getLayersOfType(FSPData)
          for (const s of allLayerSeries) {
            layers.forEach(function (data) {
              const ss = [...data.series.keys()]
              if (ss.includes(s[1]) && // In layer
                !oneLayerSeries.find(ols => ols[0] === data.id && ols[1] === s[1])) { // Not repeated
                oneLayerSeries.push([data.id, s[1]])
              }
            })
          }
          this.series = oneLayerSeries.map(s => new PlotTimeSeries(s[0], s[1]))
        })
      } else if (selection.comparisonPlot) {
        const plot = selection.comparisonPlot
        var layerX = FSPModel.getLayerById(plot.x.layerId)
        this.setUpScatterPlot(layerX, plot.x.item, plot.y.item, plot.groupSecs)
      }
      this.dialogSelection = selection
    },

    clearPlot () {
      this.series = []
      this.dialogSelection = {}
    },

    setTime (t) {
      if (t != null) {
        const nowMs = t.getTime()
        this.timeRange = [new Date(nowMs - this.timespan),
          new Date(nowMs + this.timespan)]
      }
    },

    setUpScatterPlot (layer, xSeriesName, ySeriesName, groupSecs) {
      const xs = layer.getSeries(xSeriesName)
      const ys = layer.getSeries(ySeriesName)
      const points = []
      if (xs && ys) {
        for (var i = 0; i < ys.length; i++) {
          points.push({ x: ys[i], y: xs[i] })
        }
      }

      this.scatterDataPoints = {
        layerId: layer.id,
        label: `${xSeriesName} vs. ${ySeriesName}`,
        xSeriesName: xSeriesName,
        ySeriesName: ySeriesName,
        points: points,
        times: layer.getSeries(TIME_SERIES_NAME),
        groupSecs: groupSecs
      }
    },

    curstomPointRadius (ctx) {
      return ctx.index === this.$data.time ? 100.0 : 1.0
    }
  },

  mounted () {
    EventBus.$on(EventBus.LAYER_LOADED, (layer) => {
      // const ls = FSPModel.getLayersOfType(FSPData)
      // if (ls.length == 1) {
      //   if (layer instanceof FSPData) {
      //     const names = layer.getSeriesNames()
      //     this.dialogChangedSeries({[[layer.id, names[0]]]})
      //   }
      // }
    })

    EventBus.$on(EventBus.LAYER_REMOVED, (id) => {
      // this.series = this.series.filter((s) => s.layerId != id)

      this.$nextTick(() => {
        this.series = this.series.filter((s) => s.layerId !== id)
      })

      if (this.scatterDataPoints.layerId === id) {
        this.scatterDataPoints = emptyScatterDataPoints
      }
    })

    EventBus.$on(EventBus.TIME_CHANGED, (t) => {
      this.setTime(t)
    })

    EventBus.$on(EventBus.LAYER_CHANGED, (id) => {
      this.series = this.series.map(s => new PlotTimeSeries(s.layerId, s.name))
    })
  },

  data () {
    return {
      scatterDataPoints: {
        label: '',
        points: [],
        times: [],
        groupSecs: 50
      },
      time: 0,
      timeRange: [0, 100],
      series: [],
      timespan: 1000 * 60, // 1 min
      adjustYAxis: true,
      menu: false,
      dialogSelection: {}
    }
  },

  watch: {
    timespan () {
      console.log('Time span: ' + this.timespan)
      this.setTime(FSPModel.clock.playerTime)
    }
  }
}
</script>
