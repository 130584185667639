<template>
  <div>
    <v-container>
      <div class="grid-title text-center">{{ gridTitle }}</div>
      <v-row align="center" justify="center">
        <v-col
          v-for="(item, index) in visibleItems"
          :key="index"
          cols="auto"
          class="grid-item"
          @click="onClickCallback(item)"
        >
          <!-- Reemplaza el siguiente v-icon con una etiqueta img -->
          <!--<v-icon left>{{ item.icon }}</v-icon>-->
          <img :src="item.icon" alt="My custom icon" class="custom-icon" />
          <div class="item-text">
            <div class="item-title">{{  item.title.toUpperCase() }}</div>
            <div class="item-date">{{ item.date }}</div>
          </div>
        </v-col>
      </v-row>
      <div class="text-center mb-2">
        <v-btn small outlined class="view-more-btn" @click="toggleItems">
          {{ buttonText }}
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FSPEventGrid',
  props: {
    items: {
      type: Array,
      required: true
    },
    gridTitle: {
      type: String,
      required: true
    },
    N: {
      type: Number,
      required: true
    },
    onClickCallback: {
      type: Function,
      default: (item) => { console.log('Click', item) }
    }
  },
  data () {
    return {
      showAll: false
    }
  },
  computed: {
    visibleItems () {
      return this.showAll ? this.items : this.items.slice(0, this.N)
    },
    buttonText () {
      return this.showAll ? 'VIEW LESS' : 'VIEW MORE'
    }
  },
  methods: {
    toggleItems () {
      this.showAll = !this.showAll
    },
    showAlert (item) {
      alert(item.title + ' was clicked')
    }
  }
}
</script>

  <style scoped>
  .grid-item {
      width: 250px;
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 10px;
      border: 2px solid white;
      border-radius: 10px;
      transition: all 0.3s ease; /* Agregar transición para suavizar el cambio */
  }

  .grid-item:hover {
      background-color: #f9f9f939; /* Cambiar el color de fondo al pasar el mouse sobre el botón */
      border-color: #3498db; /* Cambiar el color del borde al pasar el mouse sobre el botón */
      box-shadow: 0 0 5px #3498db; /* Agregar sombra al pasar el mouse sobre el botón */
  }

  .item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .item-title {
    font-weight: bold;
    font-size: 14px;
  }

  .item-date {
    font-size: 12px;
  }

  .grid-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .view-more-btn {
    border: 2px solid white;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 10px !important;
    transition: all 0.3s ease; /* Agregar transición para suavizar el cambio */
  }

  .view-more-btn:hover {
      background-color: #f9f9f939; /* Cambiar el color de fondo al pasar el mouse sobre el botón */
      border-color: #3498db; /* Cambiar el color del borde al pasar el mouse sobre el botón */
      box-shadow: 0 0 5px #3498db; /* Agregar sombra al pasar el mouse sobre el botón */
  }


  .custom-icon {
    width: 15px; /* Ajusta el tamaño según sea necesario */
    height: 24px; /* Ajusta el tamaño según sea necesario */
    margin-right: 8px; /* Añade margen a la derecha del ícono */
    margin-left: 8px;
  }
  </style>
