import L from 'leaflet'
import { TimeFrameFSPLayer } from './FSPLayer'

export class BuoyLayer extends TimeFrameFSPLayer {
  constructor (name, data) {
    super(name)
    let c = 1
    try {
      this.buoys = new Map()
      for (; c < data.length; c++) {
        if (data[c].length === 4) {
          const name = data[c][1]
          const pos = {
            pos: new L.LatLng(data[c][2], data[c][3]),
            time: new Date(data[c][0])
          }

          const prevPositions = this.buoys.get(name) || []
          prevPositions.push(pos)
          this.buoys.set(name, prevPositions)
        }
      }

      this.buoys.forEach((v, k) => {
        v.sort((p0, p1) => { return p0.time < p1.time })
      })
    } catch {
      throw new Error('Problem parsing Buoy CSV at line ' + c)
    }

    if (this.buoys.size === 0) {
      throw new Error('Problem parsing Buoy CSV. No buoys in file.')
    }
  }

  get minDate () {
    let min = null
    for (var b of this.buoys) {
      const positions = b[1]
      const first = positions[0].time
      min = min != null ? new Date(Math.min(min, first)) : first
    }
    return min == null ? new Date(NaN) : min
  }

  get maxDate () {
    let max = null
    for (var b of this.buoys) {
      const positions = b[1]
      const last = positions[positions.length - 1].time
      max = max != null ? new Date(Math.max(max, last)) : last
    }
    return max == null ? new Date(NaN) : max
  }

  set minDate (minDate) {
    var dt = this.minDate.getTime() - minDate.getTime()
    var times = []
    for (var b of this.buoys) {
      for (var pos of b[1]) {
        pos.time = new Date(pos.time.getTime() - dt)
        times.push(pos.time.getTime())
      }
    }
    console.log(`New buoys dates ${this.minDate} - ${this.maxDate}`)
    this.notifyChange()
  }
}
