import L from 'leaflet'
import { TimeFrameFSPLayer } from './FSPLayer'
import { FSPModel } from '../../Globals'
import { getRandomHexColor } from '../ColorUtils'
const dataForge = require('data-forge')

function readTimeSeriesFromCVS (data, name) {
  const colIndex = data[0].indexOf(name)
  const vs = []
  for (let i = 1; i < data.length; i++) {
    vs.push(data[i][colIndex])
  }
  return vs
}

function readRouteFromCVS (data) {
  return {
    lats: readTimeSeriesFromCVS(data, 'Lat'),
    lons: readTimeSeriesFromCVS(data, 'Lon'),
    dirs: readTimeSeriesFromCVS(data, 'CURRENTDIRECTION')
  }
}

function formatTime (t, includeDate) {
  const hours = new Intl.DateTimeFormat('en', {
    hour: '2-digit',
    hourCycle: 'h23'
  }).format(t)
  const minutes = new Intl.DateTimeFormat('en', {
    minute: '2-digit'
  }).format(t)
  const seconds = new Intl.DateTimeFormat('en', {
    second: '2-digit'
  }).format(t)
  const time = `${hours}:${minutes}:${seconds}`
  if (!includeDate) {
    return time
  } else {
    const date = new Intl.DateTimeFormat('en', {}).format(t)
    return `${date} ` + time
  }
}

export const TIME_SERIES_NAME = 'TIME'
const DATE_SERIES_NAME = 'DATE'
const LAT_SERIES_NAME = 'LAT'
const LON_SERIES_NAME = 'LON'
const ISO_TIME_SERIES_NAME = 'ISODateTimeUTC'
const COURSE_SERIES_NAME = 'COURSE'
const mandatorySeries = [TIME_SERIES_NAME, LAT_SERIES_NAME, LON_SERIES_NAME]

const seriesColorPalette = ['rgb(6,150,104)', 'rgb(142,212,198)', 'rgb(32,80,46)', 'rgb(64,225,140)', 'rgb(51,52,174)', 'rgb(223,204,250)', 'rgb(135,48,115)', 'rgb(68,124,254)', 'rgb(197,223,114)', 'rgb(55,68,117)', 'rgb(242,131,227)', 'rgb(90,136,174)', 'rgb(54,229,21)', 'rgb(231,23,97)', 'rgb(248,204,166)']

function getBoatColorByIndex (index) {
  const colorList = [
    '#FF0000', // Red
    '#008000', // Green
    '#0000FF', // Blue
    '#000000', // Black
    '#FFA500', // Orange
    '#696969' // Dark grey
  ]

  if (index >= 0 && index < colorList.length) {
    return colorList[index]
  } else {
    return getRandomHexColor()
  }
}

let categories = null
export function loadCategoriesMapFromFile () {
  return new Promise(resolve => {
    if (categories == null) {
      try {
        fetch('/fspdata_categories.csv', { cache: 'no-cache' })
          .then((response) => response.text())
          .then((text) => {
            const Papa = require('papaparse')
            Papa.parse(text, {
              complete: (csv) => {
                console.log('FSPData Categories: ')
                console.log(csv.data)

                categories = new Map()
                for (const row of csv.data) {
                  const rowTrimmed = row.map(s => s.trim().toUpperCase())
                  categories.set(rowTrimmed[0], rowTrimmed.slice(1))
                }
                resolve(categories)
              },
              error: (_err, _file, _inputElem, reason) => {
                throw new Error('Error reading fspdata_categories.csv ' + reason)
              }
            })
          })
      } catch (error) {
        console.error(error)
        categories = new Map([
          ['WIND',	['TWD',	'TWA',	'TWS',	'AWD',	'AWA',	'AWS']],
          ['THE BOAT',	['COURSE',	'BS',	'HEEL',	'PITCH',	'DR',	'DRA',	'DIST',	'HEEL_A',	'HEEL_FREQ',	'TRIM', 'MASTHDG', 'RUDDERHDG']],
          ['THE SAILOR',	['BPM']],
          ['TARGETS ',	['VMGTGT']],
          ['ANALYTICS',	['VMG',	'VMC',	'DMG', 'BSTGT', 'TWATGT']],
          ['MARKS',	[]],
          ['AVERAGE',	['TWDMIN',	'TWDMAX',	'TWSMIN',	'TWSMAX',	'TWAMIN',	'TWAMAX', 'BSMIN',	'BSMAX']],
          ['CUSTOM',	['AWDMIN',	'AWDMAX',	'AWAMIN',	'AWAMAX',	'AWSMIN',	'AWSMAX']]
        ])
        resolve(categories)
      }
    } else {
      resolve(categories)
    }
  })
}

// function createDataFrame (values, columnNames) {
//   // Computing rows casting all possible values to float unless the value is a date
//   const rows = values.map(row => {
//     const newRow = {}
//     for (let i = 0; i < row.length; i++) {
//       const value = row[i]
//       const columnName = columnNames[i]
//       const isTime = columnName === ISO_TIME_SERIES_NAME
//       if (isTime) {
//         newRow[TIME_SERIES_NAME] = new Date(Date.parse(value))
//       } else {
//         newRow[columnName] = parseFloat(value)
//       }
//     }
//     return newRow
//   })

//   const df = new dataForge.DataFrame(rows)
//   return df
// }

class FSPData extends TimeFrameFSPLayer {
  sportmanId = ''
  #color = '#FF0000'
  boatIcon = ''
  #units = {} // private
  #cachedRoute = null // lazy

  ROUTE_MAX_DELTA_MSEC = 2000
  ROUTE_MAX_DELTA_DEGREES = 1e-4
  static MAX_SERIES_SIZE = 2000

  constructor (name, sportmanId, data) {
    super(name)
    this.sportmanId = sportmanId
    this.#color = getBoatColorByIndex(this.id) // boatColorPalette[this.id % boatColorPalette.length]
    // check color is hex
    if (!/^#[0-9A-F]{6}$/i.test(this.#color)) {
      throw new Error(`Color ${this.#color} is not a valid hex color`)
    }
    this.boatIcon = require('../../assets/boat_marker.png')

    let headerRow = 0
    for (headerRow = 0; headerRow < data[0].length; headerRow++) {
      if (data[headerRow].some(s => s.includes(LAT_SERIES_NAME))) {
        break
      }
    }

    if (categories == null) {
      throw new Error('FSPData Series Categories not loaded')
    }

    // Removing units from header
    this.#units = {}
    const regex = /([^\(]+)(\((.+)\))?/gm
    data[headerRow] = data[headerRow].map(s => {
      let found = s.matchAll(regex)
      found = found.next().value
      const series = found[1].trim()
      const units = found[3] ? found[3].trim() : undefined
      this.#units[series] = units
      return series
    })

    // Values from headerRow+1 to last row. Rename ISO_TIME_SERIES_NAME to TIME_SERIES_NAME
    const columnNames = data[headerRow].map(s => s === ISO_TIME_SERIES_NAME ? TIME_SERIES_NAME : s)

    // check mandatorySeries
    for (const s of mandatorySeries) {
      if (!columnNames.includes(s)) {
        throw new Error(`Series ${s} not found`)
      }
    }

    const indexOfTime = columnNames.indexOf(TIME_SERIES_NAME)

    let values = data.slice(headerRow + 1, data.length - 1)
    // Filtering all values that are not the same length as the header
    values = values.filter(row => row.length === columnNames.length)

    // Transforming times in values to Date objects or numbers
    const rows = values.map(row => {
      const newRow = row.map((value, index) => {
        return index === indexOfTime ? Date.parse(value) : parseFloat(value)
      })
      return newRow
    })

    // Creating columns as a dict that maps column names to rows
    // const columns = {}
    // for (let i = 0; i < columnNames.length; i++) {
    //   const columnName = columnNames[i]
    //   columns[columnName] = rows.map(row => row[i])
    // }
    this.series = new dataForge.DataFrame({
      columnNames: columnNames,
      rows: rows
    })

    // Check series
    this.series = FSPData.cleanSeries(this.series)
  }

  static cleanSeries (series) {
    // Removing all rows with invalid time
    const nPrev = series.count()
    series = series.where(row => !isNaN(row[TIME_SERIES_NAME]))
    series = series.orderBy(row => row[TIME_SERIES_NAME])
    series = series.sequentialDistinct(row => row[TIME_SERIES_NAME])
    const nAfter = series.count()
    if (nPrev > nAfter) {
      console.warn(`Filtering ${nPrev - nAfter} rows from ${nPrev} to ${nAfter}`)
    }

    // Decimating if needed
    if (series.count() > FSPData.MAX_SERIES_SIZE) {
      const decimatingFactor = Math.ceil(series.count() / this.MAX_SERIES_SIZE)
      const nPrev = series.count()
      let array = series.toArray()
      array = array.filter((_, i) => i % decimatingFactor === 0)
      series = new dataForge.DataFrame(array)
      console.warn(`Decimating series from ${nPrev} to ${series.count()}`)
    }

    return series
  }

  getUnits (seriesName) {
    return this.#units[seriesName]
  }

  set color (color) {
    this.#color = color
    this.notifyChange()
  }

  get color () {
    return this.#color
  }

  get minDate () {
    try {
      return new Date(this.series.getSeries(TIME_SERIES_NAME).first())
    } catch (error) {
      return null
    }
  }

  get maxDate () {
    try {
      return new Date(this.series.getSeries(TIME_SERIES_NAME).last())
    } catch (error) {
      return null
    }
  }

  set minDate (minDate) {
    const prevMinT = this.minDate.getTime()
    const newMinT = minDate.getTime()
    const newTimes = this.series.getSeries(TIME_SERIES_NAME).select(t => new Date((t - prevMinT) + newMinT))
    this.series = this.series.withSeries(TIME_SERIES_NAME, newTimes)
    this.#cachedRoute = null
    console.log(`New max date ${this.maxDate}`)
    this.notifyChange()
  }

  getTargetSeries (seriesName) {
    const targetNames = new Map()
    targetNames.set('VMG', 'VMGTgt')
    targetNames.set('BS', 'BSTgt')
    targetNames.set('TWA', 'TWATgt')
    targetNames.set('TWD', 'TWDAvg')

    try {
      const n = targetNames.get(seriesName)
      return this.getSeries(n)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  getColorForSeries (seriesName) {
    let index = 0
    const headers = this.series.getColumnNames()
    for (const key of headers) {
      if (key === seriesName) { break }
      index++
    }
    return seriesColorPalette[index % seriesColorPalette.length]
  }

  getSeriesNames () {
    return this.series.getColumnNames()
      .filter(h => ![TIME_SERIES_NAME, DATE_SERIES_NAME, LAT_SERIES_NAME, LON_SERIES_NAME].includes(h))
  }

  getSeriesCategory (name) {
    const nameUpperCase = name.toUpperCase()
    for (const [cat, list] of categories) {
      if (list.includes(nameUpperCase)) {
        return cat
      }
    }
    return null
  }

  getSeriesCategories () {
    const result = new Map()
    for (const seriesName of this.series.getColumnNames()) {
      if (![TIME_SERIES_NAME,
        DATE_SERIES_NAME,
        LAT_SERIES_NAME,
        LON_SERIES_NAME].includes(seriesName)) {
        const cat = this.getSeriesCategory(seriesName)
        if (cat) {
          if (!result.has(cat)) {
            result.set(cat, [])
          }
          result.get(cat).push(seriesName)
        } else {
          console.warn(`Series ${seriesName} not found in categories`)
        }
      }
    }
    return result
  }

  getRowForTime (selectedTime) {
    // Find first t in time series that is smaller than selectedTime
    const t = selectedTime.getTime()
    let i = this.getSeries(TIME_SERIES_NAME).findIndex((p) => p > t)
    if (i === -1) i = 0
    return i
  }

  getSeries (name) {
    if (this.series.hasSeries(name)) {
      return this.series.getSeries(name).toArray()
    }
    throw new Error(`Series ${name} not found`)
  }

  hasSeries (name) {
    return this.series.hasSeries(name)
  }

  getTimeSeries (name) {
    const series = []
    const vs = this.getSeries(name)
    const ts = this.getSeries(TIME_SERIES_NAME)
    for (let i = 0; i < ts.length; i++) {
      series.push({ x: ts[i], y: vs[i] })
    }
    return series
  }

  get route () {
    if (this.#cachedRoute == null) {
      const lats = this.getSeries(LAT_SERIES_NAME)
      const lons = this.getSeries(LON_SERIES_NAME)
      const time = this.getSeries(TIME_SERIES_NAME)
      const courseSeries = this.getSeries(COURSE_SERIES_NAME)

      this.#cachedRoute = []
      for (let i = 0; i < lats.length; i++) {
        if (isNaN(lats[i]) || isNaN(lons[i])) {
          console.log('Wrong lat lon at' + i)
          continue
        }

        const p1 = new L.LatLng(lats[i], lons[i])
        p1.time = time[i]

        if (courseSeries) {
          p1.direction = courseSeries[i]
        }

        if (this.#cachedRoute.length > 0) {
          const p0 = this.#cachedRoute[this.#cachedRoute.length - 1]
          const deltaT = p1.time - p0.time

          const deltaS = Math.max(Math.abs(p1.lat - p0.lat) + Math.abs(p1.lng - p0.lng))
          if (deltaT < this.ROUTE_MAX_DELTA_MSEC && deltaS < this.ROUTE_MAX_DELTA_DEGREES) {
            continue
          }
        }

        this.#cachedRoute.push(p1)
      }
      console.log("New route's length: " + this.#cachedRoute.length)
    }
    return this.#cachedRoute
  }

  // Append new row to series during live update
  appendValueRow (row) {
    this.series = this.series.appendRow(row)
  }

  appendCSV (csvString) {
    const columnNames = this.series.getColumnNames()
    // Parse CSV with data-forge
    let dataframe = dataForge.fromCSV(csvString, { columnNames: columnNames })
    // Converting all times in ISO format into DateTime
    let times = dataframe.getSeries(TIME_SERIES_NAME)
    times = times.select(value => Date.parse(value))
    dataframe = dataframe.withSeries(TIME_SERIES_NAME, times)

    // check first time in dataframe is after last time in series
    const firstTime = dataframe.first()[TIME_SERIES_NAME]
    const lastTime = this.series.last()[TIME_SERIES_NAME]
    if (firstTime < lastTime) {
      throw new Error(`First time in new data ${new Date(firstTime)} is before last time in series ${new Date(lastTime)}`)
    } else {
      // get gap seconds
      const gapSeconds = (firstTime - lastTime) / 1000
      if (gapSeconds > 10) {
        console.warn(`Gap between last time in series ${new Date(lastTime)} and first time in new data ${new Date(firstTime)} is ${gapSeconds} seconds` +
        `NEW DATA MAX DATE: ${new Date(lastTime)} -> ${dataframe.last()} (Current Time is ${FSPModel.clock.playerTime})`)
      }
    }

    // Concat
    this.series = this.series.concat(dataframe)

    // Invalid route
    this.#cachedRoute = null

    // Change time if needed
    if (isNaN(FSPModel.clock.playerTime)) {
      FSPModel.clock.playerTime = this.minDate
    }
    this.notifyChange()

    // console.log(this.series.toCSV()) // DEBUG
  }

  changeData (newName, newMinDate, boatIconURL) {
    this.name = newName
    this.boatIcon = boatIconURL
    this.minDate = newMinDate
    this.notifyChange()
  }
}

export { FSPData, readRouteFromCVS, readTimeSeriesFromCVS, formatTime }
