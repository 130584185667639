export function lerp (v1, v2, t) {
  return v1 * (1.0 - t) + v2 * t
}

export function interpolateAngles (startAngle, endAngle, t) {
  startAngle = startAngle % 360 // Normalizing angles to [0, 360)
  endAngle = endAngle % 360

  if (startAngle < 0) {
    startAngle += 360
  }
  if (endAngle < 0) {
    endAngle += 360
  }

  let angleDifference = endAngle - startAngle

  // Ensure the shortest path for interpolation
  if (angleDifference > 180) {
    angleDifference -= 360
  } else if (angleDifference < -180) {
    angleDifference += 360
  }

  let interpolatedAngle = startAngle + t * angleDifference
  interpolatedAngle = (interpolatedAngle + 360) % 360 // Ensure angle is within [0, 360)

  return interpolatedAngle
}
