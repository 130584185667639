import { lerp } from './MathUtils'

function hexToRgb (hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

let lastHue = Math.floor(Math.random() * 360)
export function getRandomColor () {
  const hueStep = 137.5
  lastHue = (lastHue + hueStep) % 360
  const saturation = 100
  const lightness = 50
  return `hsl(${lastHue}, ${saturation}%, ${lightness}%)`
}

export function hslToHex (h, s, l) {
  l /= 100
  const a = s * Math.min(l, 1 - l) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color).toString(16).padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export function getRandomHexColor () {
  const hueStep = 137.5
  lastHue = (lastHue + hueStep) % 360
  const saturation = 100
  const lightness = 50
  return hslToHex(lastHue, saturation, lightness)
}

const tintedIcons = new Map()

export function getTintedIcon (base64Input, colorHex, mixRatio, callback) {
  // console.log('getTintedIcon', base64Input, colorHex, mixRatio)

  // check if icon is already tinted
  const key = base64Input + colorHex + mixRatio
  if (tintedIcons.has(key)) {
    callback(tintedIcons.get(key))
    return
  }

  var img = new Image()
  img.src = base64Input

  img.onload = function () {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.width = img.width
    canvas.height = img.height

    context.drawImage(img, 0, 0, img.width, img.height)

    const imageData = context.getImageData(0, 0, img.width, img.height)
    const data = imageData.data

    const rgbColor = hexToRgb(colorHex)
    for (let i = 0; i < data.length; i += 4) {
      data[i] = lerp(data[i], rgbColor.r, mixRatio)
      data[i + 1] = lerp(data[i + 1], rgbColor.g, mixRatio)
      data[i + 2] = lerp(data[i + 2], rgbColor.b, mixRatio)
    }

    context.putImageData(imageData, 0, 0)

    const base64Output = canvas.toDataURL('image/png')

    callback(base64Output)
  }
}
